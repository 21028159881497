import React, { useEffect, useState } from "react";
import "./user.css";
import { useGetUsersQuery } from "../../../context/service/user.service";
import { useDeleteUserMutation } from "../../../context/service/user.service";
import { useChangeStatusMutation } from "../../../context/service/auth.service";
import { enqueueSnackbar as EnSn } from "notistack";
import { Link, NavLink, useLocation } from "react-router-dom";
import Pagination from '../../../utils/Pagination';

export const User = () => {
    const location = useLocation();
  const filter = location.search.split("=").pop() || "all";
  const [page, setPage] = useState(1);
  const { data = null, isLoading } = useGetUsersQuery({ page, role: filter });
  const [changeStatus] = useChangeStatusMutation();
  const [deleteUser] = useDeleteUserMutation();
  const totalCount = data?.innerData?.totalCount || 0; 
  const pageSize = data?.innerData?.pageSize || 10; 
  const totalPages = data?.innerData?.totalPages; 
  const users = data?.innerData?.results || null;
  const user = JSON.parse(localStorage.getItem("user"));
  const handlePageChange = (newPage) => {
        setPage(newPage);
    };


  // Handle change status
  const handleStatusChange = async (user) => {
    try {
      // confirm change status
      let action = user.status === "active" ? "suspended" : "active";
      let set = { id: user.id, status: action };
      const { data = null, error = null } = await changeStatus(set);
      let msg = data?.message || error?.data?.message;
      let variant = data?.variant || error?.data?.variant;
      if (error) return EnSn(msg, { variant });
      EnSn(msg, { variant: data?.variant || error?.data?.variant });
    } catch (error) {
      return EnSn("Failed to change status", { variant: "error" });
    }
  };

  // Handle delete user
  const handleDeleteUser = async (id) => {
    try {
      // confirm delete
      let msg = "Вы хотите удалить следующий профиль?";
      if (!window.confirm(msg)) return;
      const { data = null, error = null } = await deleteUser(id);
      msg = data?.message || error?.data?.message;
      let variant = data?.variant || error?.data?.variant;
      if (error) return EnSn(msg, { variant });
      EnSn(msg, { variant });
      alert("User deleted");
    } catch (error) {
      return EnSn("Failed to delete user", { variant: "error" });
    }
  };

  if (!users?.length && !isLoading) {
    return (
      <div className="user">
        <div className="home__content">
          <h1 style={{ lineHeight: "1.5" }}>
            В настоящее время пользователей нет. Как только появится новая
            информация, она будет отображена здесь.
          </h1>
        </div>
      </div>
    );
  }

  return (
    <div className="user">
      <div className="home__content">
        <h1>Список пользователей:</h1>
        {user?.role === 'admin' && (
        <div className="list_filter">
          <NavLink to="/users" className={({ isActive }) => isActive && filter === 'all' ? 'active' : ''}>Все</NavLink>
          <NavLink to="/users?role=manager" className={({ isActive }) => isActive && filter === 'manager' ? 'active' : ''}>Менеджеры</NavLink>
          <NavLink to="/users?role=organization" className={({ isActive }) => isActive && filter === 'organization' ? 'active' : ''}>Организации</NavLink>
          <NavLink to="/users?role=moderator" className={({ isActive }) => isActive && filter === 'moderator' ? 'active' : ''}>Модератор</NavLink>
          <NavLink to="/users?role=admin" className={({ isActive }) => isActive && filter === 'admin' ? 'active' : ''}>Администраторы</NavLink>

        </div>
        )}
        <div className="home__list">
          <ol className="list_users">
            <li className="list_users__header">
              <p className="w150">ФИО</p>
              <p className="w150">Почта</p>
              <p className="w150">Телефон</p>
              <p className="w150">Роль</p>
              <p className="w150">Организация</p>
            </li>

            {users?.map((user_item) => {
              const roleTranslations = {
                admin: "Администратор",
                manager: "Менеджер",
                organization: "Организация",
                moderator : 'Модератор'
              };
              return (
                <li className="list_users__item" key={user_item?.id}>
                  <p className="w150">
                    {`${user_item.fristname} ${user_item?.lastname} ${user_item.patronymic}`}
                  </p>
                  <p className="w150">{user_item?.email}</p>
                  <p className="w150">{user_item?.phone}</p>
                  <p className="w150">
                  {roleTranslations[user_item?.role] || user_item?.role} {/* Выводим роль с переводом */}
                  </p>

                  <p>{user_item?.org}</p>
                  <div className="list_users__item__actions">
                    <Link to={`/users/list/${user_item.id}`}>
                      <button>Расчеты</button>
                    </Link>
                    <Link to={`/users/edit/${user_item.id}`}>
                      <button>Редактировать</button>
                    </Link>
                    <button onClick={() => handleStatusChange(user_item)}>
                      {user_item?.status === "active" ? "Отключить" : "Активировать"}
                    </button>
                    {user?.role === 'admin' && (
                    <button className="delete" onClick={() => handleDeleteUser(user_item?.id)}>
                      Удалить
                    </button>
                    )}
                  </div>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
            {totalPages > 1 && (
                <Pagination
                    currentPage={page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            )}
    </div>
  );
};
