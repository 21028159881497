import React, { useState } from "react";
import "./style.css";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { NumericFormat, PatternFormat } from "react-number-format";
import { useGetUserByIdQuery } from "../../context/service/user.service";
import { useUpdateUserMutation } from "../../context/service/user.service";
import { useUpdateUserLogoMutation } from "../../context/service/user.service";
import { enqueueSnackbar as EnSn } from "notistack";

export const Organization = () => {
  const [show, setShow] = useState({ old: false, new: false, repeat: false });
  const [logo, setLogo] = useState(null);
  const local = JSON.parse(localStorage.getItem("user"));
  const { data = null, isLoading } = useGetUserByIdQuery(local?.id);
  const user = data?.innerData;

  const handleShow = (type) => {
    setShow((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  // handle form submit
  const [updateUser] = useUpdateUserMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    let value, msg, variant, next;
    value = Object.fromEntries(new FormData(e.target));

    // new password and repeat password must be equal

    msg = "Новый пароль и повторите пароль должны совпадать";
    next = value.new_password !== value.repeat_password;
    if (next) return EnSn(msg, { variant: "warning" });
    delete value.repeat_password;
    if (value.phone) {
        value.phone = value.phone.replace(/[\s()-]/g, ''); // Удаляем пробелы, тире и скобки
    }
    value.markup = Number(value.markup.replace(" %", ""));
    // clear empty values
    for (const key in value) if (!value[key]) delete value[key];
    try {
        const { data } = await updateUser ({ id: user.id, body: value }).unwrap();
        msg = data?.message || "Успешное обновление пользователя"; // Сообщение по умолчанию
        variant = data?.variant || "success"; // Значение по умолчанию
        EnSn(msg, { variant });
    } catch (error) {
        msg = error?.data?.message || "Ошибка при обновлении пользователя";
        variant = error?.data?.variant || "error"; // Значение по умолчанию
        EnSn(msg, { variant });
    }
  };

  const [updateLogo] = useUpdateUserLogoMutation();
  const handleUploadLogo = async (logo) => {
    try {
      let msg, variant;
      const formData = new FormData();
      formData.append("logo", logo);
      const { data, error } = await updateLogo({ id: user.id, body: formData });
      msg = data?.message || error?.data?.message;
      variant = data?.variant || error?.data?.variant || "error";
      if (error) return EnSn(msg, { variant });
      EnSn(msg, { variant });
    } catch (error) {
      EnSn("Слишком большой размер файла", { variant: "error" });
      setTimeout(() => window.location.reload(), 1000);
    }
  };

  return (
    <div className="profile">
      <form className="profile__form" onSubmit={handleSubmit}>
        <div className="profile__form__left">
          <h1>Название организации/ ФИО</h1>
          <div>
            <label>
              <input type="email" name="email" placeholder="Емаил"  defaultValue={user?.email || ''}/>
            </label>
            <label>
              <input
                type={show.old ? "text" : "password"}
                placeholder="Старый пароль"
                name="old_password"
                autoComplete="new-password"
              />
              <button onClick={() => handleShow("old")} type="button">
                {show.old ? <IoEyeOutline /> : <IoEyeOffOutline />}
              </button>
            </label>
            <label>
              <input
                type={show.new ? "text" : "password"}
                placeholder="Новый пароль"
                name="new_password"
              />
              <button onClick={() => handleShow("new")} type="button">
                {show.new ? <IoEyeOutline /> : <IoEyeOffOutline />}
              </button>
            </label>
            <label>
              <input
                type={show.repeat ? "text" : "password"}
                placeholder="Повторите пароль"
                name="repeat_password"
              />
              <button onClick={() => handleShow("repeat")} type="button">
                {show.repeat ? <IoEyeOutline /> : <IoEyeOffOutline />}
              </button>
            </label>
          </div>

          <div>
            <label>
              <PatternFormat
                format="+# (###) ###-##-##"
                allowEmptyFormatting
                mask="_"
                name="phone"
                value={user?.phone || ''}
                style={{ letterSpacing: "2px" }}
              />
            </label>
            <label>
              <input type="text" name="lastname" placeholder="Фамилия" defaultValue={user?.lastname || ''}/>
            </label>
            <label>
              <input type="text" name="fristname" placeholder="Имя" defaultValue={user?.fristname || ''}/>
            </label>
            <label>
              <input type="text" name="patronymic" placeholder="Отчество" defaultValue={user?.patronymic || ''}/>
            </label>
          </div>

          <div className="profile__requisites">
            <h1>Реквизиты</h1>
            <label className="wf100">
              <input type="text" placeholder="Название:" name="name" defaultValue={user?.name || ''}/>
            </label>
            <label className="wf100">
              <input type="text" placeholder="Юр Адрес:" name="address" defaultValue={user?.address || ''}/>
            </label>
            <label className="wf50">
              <input type="text" placeholder="БИК" name="bik" defaultValue={user?.bik || ''}/>
            </label>
            <label className="wf50">
              <input type="text" placeholder="КПП" name="kpp" defaultValue={user?.kpp || ''}/>
            </label>
            <label className="wf50">
              <input type="text" placeholder="Р/С" name="rs" defaultValue={user?.rs || ''}/>
            </label>
            <label className="wf50">
              <input type="text" placeholder="ОГРН" name="ogrn" defaultValue={user?.ogrn || ''}/>
            </label>
            <label className="wf50">
              <input type="text" placeholder="К/С" name="ks" defaultValue={user?.ks || ''}/>
            </label>
            <label className="wf50">
              <input type="text" placeholder="Банк" name="bank" defaultValue={user?.bank || ''}/>
            </label>
          </div>
        </div>

        <div className="profile__form__right">
          <div>
            <p>Процент наценки</p>
            <NumericFormat
              allowLeadingZeros
              thousandSeparator=""
              suffix=" %"
              placeholder="0 %"
              defaultValue={user?.markup || 0}
              name="markup"
            />
          </div>

          <figure>
            {logo ? (
              <img
                src={logo ? URL.createObjectURL(logo) : "/icons/pic.svg"}
                alt="logo"
                className={logo ? "selected" : ""}
              />
            ) : (
              <img className="selected" src={user?.logo} alt="" />
            )}
          </figure>

          <label>
            <input
              type="file"
              hidden
              onChange={(e) => {
                setLogo(e.target.files[0]);
                handleUploadLogo(e.target.files[0]);
              }}
              accept="image/*"
            />
            <img src="/icons/download.svg" alt="" />
            <span>Загрузить логотип</span>
          </label>

          <button>Сохранить</button>
        </div>
      </form>
    </div>
  );
};
