import React, { memo, useState, useEffect } from "react";
import "./layout.css";
import { Outlet } from "react-router-dom";
import { Link, NavLink, useLocation } from "react-router-dom";
import { CgMenuRightAlt } from "react-icons/cg";
import { IoClose } from "react-icons/io5";

export const Layout = memo(() => {
  const location = useLocation();
  const [menu, setMenu] = useState(false);
  const isLogin = location?.pathname === "/login";
  const [role, setRole] = useState("");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) setRole(user.role);
  }, [location]);
var logout = '';
if (!isLogin) {
  logout = <NavLink to="/logout"> <img src="/icons/exit.svg" alt="Выход" /><span>Выход</span></NavLink>;
}

  return (
    <main className="main">
      <header className="header">
        <div className="container">
          <div className="header__desktop">
            <Link>
              <img src="/icons/logo.svg" alt="" />
            </Link>

            <div style={{ display: 'flex' }}>
            {(role === 'admin' || role === 'moderator') && (
              <nav
                className="header__desktop__nav"
              >
                <NavLink to="/">
                  <img src="/icons/home.svg" alt="home" />
                  <span>Главная</span>
                </NavLink>

                <NavLink to="/calculation">
                  <img src="/icons/calculation.svg" alt="calculation" />
                  <span>Калькулятор</span>
                </NavLink>

                <NavLink to="/archive">
                  <img src="/icons/calculation.svg" alt="calculation" />
                  <span>Расчеты</span>
                </NavLink>

                <NavLink to="/users">
                  <img src="/icons/profile.svg" alt="user" />
                  <span>Пользователи</span>
                </NavLink>

                <NavLink to="/cost?product=material">
                  <img src="/icons/cost.svg" alt="cost" />
                  <span>Стоимости</span>
                </NavLink>
                    {!isLogin && (
                        <NavLink to="/logout">
                            <img src="/icons/exit.svg" alt="Выход" />
                            <span>Выход</span>
                        </NavLink>
                    )}
              </nav>
              )}
              {(role === 'manager') && (
              <nav
                className="header__desktop__nav"
              >
                <NavLink to="/">
                  <img src="/icons/calculation.svg" alt="calculation" />
                  <span>Расчеты</span>
                </NavLink>

                <NavLink to="/archive">
                  <img src="/icons/archive.svg" alt="archive" />
                  <span>Архив</span>
                </NavLink>

                <NavLink to="/profile">
                  <img src="/icons/cost.svg" alt="cost" />
                  <span>Профиль</span>
                </NavLink>
                    {!isLogin && (
                        <NavLink to="/logout">
                            <img src="/icons/exit.svg" alt="Выход" />
                            <span>Выход</span>
                        </NavLink>
                    )}
              </nav>
              )}
              {(role === 'organization') && (
              <nav
                className="header__desktop__nav"
              >
                <NavLink to="/">
                  <img src="/icons/calculation.svg" alt="calculation" />
                  <span>Расчеты</span>
                </NavLink>

                <NavLink to="/archive">
                  <img src="/icons/archive.svg" alt="archive" />
                  <span>Архив</span>
                </NavLink>

                <NavLink to="/users">
                  <img src="/icons/profile.svg" alt="user" />
                  <span>Пользователи</span>
                </NavLink>

                <NavLink to="/profile">
                  <img src="/icons/cost.svg" alt="cost" />
                  <span>Профиль</span>
                </NavLink>
                    {!isLogin && (
                        <NavLink to="/logout">
                            <img src="/icons/exit.svg" alt="Выход" />
                            <span>Выход</span>
                        </NavLink>
                    )}
              </nav>
              )}
            </div>


            <div
              className="header__desktop__content"
              style={{ display: isLogin ? "none" : "flex" }}
            >
              <a href="tel:+79936310303">
                <img src="/icons/phone.svg" alt="" />
                <span>+7 (993) 631-03-03</span>
              </a>

              <a href="mailto:">
                <img src="/icons/mail.svg" alt="" />
                <span>info@stone-craft.ru</span>
              </a>

              <button onClick={() => setMenu(!menu)}>
                {menu ? <IoClose /> : <CgMenuRightAlt />}
              </button>
            </div>
          </div>

          <div className={`header__mobile ${menu ? "active" : ""}`}>
            {(role === 'admin' || role === 'moderator') && (
              <nav
                className="header__mobile__nav"
              >
                <NavLink to="/" onClick={() => setMenu(!menu)}>
                  <img src="/icons/home.svg" alt="home" />
                  <span>Главная</span>
                </NavLink>

                <NavLink to="/calculation" onClick={() => setMenu(!menu)}>
                  <img src="/icons/calculation.svg" alt="calculation" />
                  <span>Расчеты</span>
                </NavLink>

                <NavLink to="/user" onClick={() => setMenu(!menu)}>
                  <img src="/icons/profile.svg" alt="user" />
                  <span>Пользователи</span>
                </NavLink>

                <NavLink to="/organization" onClick={() => setMenu(!menu)}>
                  <img src="/icons/organization.svg" alt="organization" />
                  <span>Организации</span>
                </NavLink>

                <NavLink to="/cost?product=material" onClick={() => setMenu(!menu)}>
                  <img src="/icons/cost.svg" alt="cost" />
                  <span>Стоимости</span>
                </NavLink>
                    {!isLogin && (
                        <NavLink to="/logout" onClick={() => setMenu(!menu)}>
                            <img src="/icons/exit.svg" alt="Выход" />
                            <span>Выход</span>
                        </NavLink>
                    )}
              </nav>
              )}
              {(role === 'manager' || role === 'organization') && (
              <nav
                className="header__mobile__nav"
              >
                <NavLink to="/" onClick={() => setMenu(!menu)}>
                  <img src="/icons/calculation.svg" alt="calculation" />
                  <span>Расчеты</span>
                </NavLink>

                <NavLink to="/archive" onClick={() => setMenu(!menu)}>
                  <img src="/icons/archive.svg" alt="archive" />
                  <span>Архив</span>
                </NavLink>

                <NavLink to="/profile" onClick={() => setMenu(!menu)}>
                  <img src="/icons/cost.svg" alt="cost" />
                  <span>Профиль</span>
                </NavLink>
                    {!isLogin && (
                        <NavLink to="/logout"  onClick={() => setMenu(!menu)}>
                            <img src="/icons/exit.svg" alt="Выход" />
                            <span>Выход</span>
                        </NavLink>
                    )}
              </nav>
              )}
            <div className="header__mobile__content">
              <a href="tel:+79936310303">
                <img src="/icons/phone.svg" alt="" />
                <span>+7 (993) 631-03-03</span>
              </a>

              <a href="mailto:info@stone-craft.ru">
                <img src="/icons/mail.svg" alt="" />
                <span>info@stone-craft.ru</span>
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="section">
        <div className="container">
          <Outlet />
        </div>
      </section>
      <footer className="footer">
        <div className="container">
          <Link>
            <img src="/icons/logo.svg" alt="" />
          </Link>

          <ol>
            <li>
              <a href="tel:+79936310303">
                <img src="/icons/phone.svg" alt="" />
                <span>+7 (993) 631-03-03</span>
              </a>
            </li>
            <li>
              <a href="mailto:inf0@stone-craft.ru">
                <img src="/icons/mail.svg" alt="" />
                <span>inf0@stone-craft.ru</span>
              </a>
            </li>
          </ol>
        </div>
      </footer>
    </main>
  );
});
