import React, { useState } from "react";
import "./archive.css";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { enqueueSnackbar as EnSn } from "notistack";
import { useGetCalculatorsByArchiveQuery, useDeleteCalculatorMutation, useInWorkCalculatorMutation, useIsPaidCalculatorMutation, useSendPaidCalculatorMutation } from '../../context/service/calculator.service';
import { useGetUserByIdQuery } from '../../context/service/user.service';
import Pagination from '../../utils/Pagination';
import { useParams, useLocation, Link } from 'react-router-dom';
import { NumericFormat } from "react-number-format";
import Invoices from './Invoices';

export const Archive = () => {
    document.title = 'Архив расчетов';
    const [page, setPage] = useState(1);
    const { user_id } = useParams();
    const { data, error, isLoading } = useGetCalculatorsByArchiveQuery({ page, user_id: user_id });
    const [deleteCalculator] = useDeleteCalculatorMutation();
    const [inWorkCalculator] = useInWorkCalculatorMutation();
    const [isPaidCalculator] = useIsPaidCalculatorMutation();
    const [sendPaidMail] = useSendPaidCalculatorMutation();

    const archive = data?.innerData?.results || [];
    const totalCount = data?.innerData?.totalCount || 0; 
    const pageSize = data?.innerData?.pageSize || 10; 
    const totalPages = data?.innerData?.totalPages; 
    const user = JSON.parse(localStorage.getItem("user"));

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    if (isLoading) return <div>Загрузка...</div>;
    if (error) return <div>Ошибка загрузки архивов</div>;

    const handleSendToWork = async (id) => {
        const confirmed = window.confirm("Вы уверены, что хотите отправить в работу этот расчет?");
        if (!confirmed) return;

        let msg, variant;
        const { data = null, error = null } = await inWorkCalculator(id);
        msg = data ? data.message : error?.data?.error;
        variant = data ? data.variant : error?.data?.variant;

        EnSn(msg, { variant });
    };

    const handleDeleteCalc = async (id) => {
        const confirmed = window.confirm("Вы уверены, что хотите удалить этот расчет?");
        if (!confirmed) return;

        let msg, variant;
        const { data = null, error = null } = await deleteCalculator(id);
        msg = data ? data.message : error?.data?.error;
        variant = data ? data.variant : error?.data?.variant;

        EnSn(msg, { variant });
    };

    const SendPaid = (id) => {
        setSelectedInvoiceId(id);
        setIsModalOpen(true);
    };

    const NewSendPaid = async (id, invoice_id = 0) => {
            const response = await sendPaidMail({ id, invoice_id }); // Используйте правильные значения
            const { data, error } = response;

            const msg = data ? data.message : error?.data?.error;
            const variant = data ? data.variant : error?.data?.variant;

            if (error) {
                return EnSn(msg, { variant });
            }
            EnSn(msg, { variant });
            setIsModalOpen(false);
    };

    const changePaid = async (id, paid) => {
        const text = (paid === '1') ? 'Вы уверены, что хотите сменить статус на оплачен?' : 'Вы уверены, что хотите сменить статус на не оплачен?';
        const confirmed = window.confirm(text);
        if (!confirmed) return;

        let msg, variant;
        const { data = null, error = null } = await isPaidCalculator({ id, paid });
        msg = data ? data.message : error?.data?.error;
        variant = data ? data.variant : error?.data?.variant;

        EnSn(msg, { variant });
    };

    return (
        <div className="archive">
            <h1>{!user_id ? 'Архив расчетов' : 'Расчёты пользователя'}</h1>

            <div className="archive__container">
                <ol className="archive__list">
                    <li className="archive__header">
                        <p className="w75">id</p>
                        <p className={user?.role === 'admin' ? 'w140' : 'w170'}>ФИО</p>
                        <p className={user?.role === 'admin' ? 'w140' : 'w170'}>{user?.role === 'admin' ? 'ИНН' : 'Материал и цвет'}</p>
                        <p className="w90">Дата</p>
                        <p className="w90">Сумма у обработчика</p>
                        <p className="w90">Сумма</p>
                        <p className="w90">Наценка</p>
                        <p className="w90">Отправлен в расчет?</p>
                        {(user?.role === 'admin') && <p className="w60">Оплачен</p>}
                    </li>

                    {archive.map((item) => {
                        const date = new Date(item.date);
                        const formattedDate = date.toLocaleDateString('ru-RU');
                        const color = JSON.parse(item.color);
                        const material = JSON.parse(item.material);

                        return (
                            <li className="archive__item" key={item.id}>
                                <p className="w75">{item.id}</p>
                                <p className={user?.role === 'admin' ? 'w140' : 'w170'}>
                                {user?.role === 'admin' ? (
                                    item.user_role === 'admin' ? (
                                        <span>Админ</span>
                                    ) : (
                                        <>
                                            {item.user_lastname} {' '}
                                            {(item.user_fristname?.charAt(0) ?? '')}. {' '}
                                            {(item.user_patronymic?.charAt(0) ?? '')}.
                                        </>
                                    )
                                ) : (
                                    item.fullname
                                )}
                                </p>
                                <p className={user?.role === 'admin' ? 'w140' : 'w170'}>
                                {user?.role === 'admin' ? (
                                    <>
                                        {item.user_role === 'admin' ? (
                                            <span>Админ</span>
                                        ) : (
                                            item.user_role === 'manager' ? item.parent_inn : item.user_inn
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {material ? material.name : 'Цвет не указан'}
                                        <br />
                                        {color ? color.name : 'Цвет не указан'}
                                    </>
                                )}

                                </p>
                                <p className="w90">{formattedDate}</p>
                                <p className="w90">
                                    <NumericFormat
                                        value={item.material_price + item.extras_price}
                                        allowLeadingZeros
                                        thousandSeparator=" "
                                        suffix=" ₽"
                                        displayType="text"
                                    />
                                </p>
                                <p className="w90">
                                    <NumericFormat
                                        value={item.material_price + item.extras_price - item.markup_price}
                                        allowLeadingZeros
                                        thousandSeparator=" "
                                        suffix=" ₽"
                                        displayType="text"
                                    />
                                </p>
                                <p className="w90">
                                    <NumericFormat
                                        value={item.markup_price}
                                        allowLeadingZeros
                                        thousandSeparator=" "
                                        suffix=" ₽"
                                        displayType="text"
                                    />
                                </p>
                                <p className="w90" style={{ borderRight: "none" }}>
                                    {item.status !== 0 ? <MdCheckBox /> : <button className="no_button" onClick={() => handleSendToWork(item.id)}><MdCheckBoxOutlineBlank /></button>}
                                </p>
                                {(user?.role === 'admin' || user?.role === 'moderator') && (
                                    <p className="w60" style={{ borderRight: "none" }}>
                                        {item.paid === '1' ? <button className="no_button" onClick={() => changePaid(item.id, '0')}><MdCheckBox /></button> : <button className="no_button" onClick={() => changePaid(item.id, '1')}><MdCheckBoxOutlineBlank /></button>}
                                    </p>
                                )}

                                <div className="archive__actions">
                                    {(item.status === 0 && user?.role !== 'admin' && user?.role !== 'moderator') && (
                                        <button onClick={() => handleSendToWork(item.id)}>Отправить в работу</button>
                                    )}
                                    <Link to={`/archive/item/${item.id}`}>
                                        <button>Открыть</button>
                                    </Link>
                                    {(user?.role === 'admin' || user?.role === 'moderator') && (
                                        <button onClick={() => SendPaid(item.id)}>Отправить</button>
                                    )}
                                    {(user?.role === 'admin') && (
                                    <button className="delete" onClick={() => handleDeleteCalc(item.id)}>Удалить</button>
                                    )}
                                </div>
                            </li>
                        );
                    })}
                </ol>
            </div>
            {totalPages > 1 && (
                <Pagination
                    currentPage={page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            )}

            <Invoices 
                isOpen={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
                id={selectedInvoiceId} // Передаем id выбранного счета
                onSendNew={(calculatorId, invoiceId) => NewSendPaid(calculatorId, invoiceId)}
            />
        </div>
    );
};
