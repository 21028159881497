import React, { memo, useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Layout } from "./layout/layout";
import { Login } from "./pages/auth/login";
import { Auth } from "./pages/auth/auth";
import { Calculation } from "./pages/calculation/calculation";
import { Archive } from "./pages/archive/archive";
import { Archive_item } from "./pages/archive/item";
import { Manager } from "./pages/profile/manager";
import { Organization } from "./pages/profile/organization";
import { Home } from "./pages/admin/home/home";
import { Cost } from "./pages/admin/cost/cost";
import { Calculation as Calc } from "./pages/admin/calculation/calculation";
import { Organization as Org } from "./pages/admin/organization/organization";
import { Org_item } from "./pages/admin/organization/item";
import { User } from "./pages/admin/user/user";
import { User_item } from "./pages/admin/user/item";

const Contact = () => <h1>Contact</h1>;
const NotFound = () => <h1>Not Found</h1>;

const Logout = ({ updateUserRole }) => {
  useEffect(() => {
    updateUserRole(null);
  }, [updateUserRole]);

  return null; // или можно вернуть какой-то индикатор загрузки
};

const AdminRoutes = ({ updateUserRole }) => (
  <Routes>
    <Route element={<Layout />}>
      <Route path="/login" element={<Login updateUserRole={updateUserRole} />} />
      <Route element={<Auth />}>
        <Route index element={<Home />} />
        <Route path="cost" element={<Cost />} />
        <Route path="calculation" element={<Calculation />} />
        <Route path="archive" element={<Archive />} />
        <Route path="archive/item/:id" element={<Archive_item />} />
        <Route path="organization" element={<Org />} />
        <Route path="organization/item/:id" element={<Org_item />} />
        <Route path="users" element={<User  />} />
        <Route path="users/edit/:id" element={<User_item />} />
        <Route path="users/list/:user_id" element={<Archive />} />
        <Route path="logout" element={<Logout updateUserRole={updateUserRole} />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);

const ManagerRoutes = ({ updateUserRole }) => (
  <Routes>
    <Route element={<Layout />}>
      <Route path="/login" element={<Login updateUserRole={updateUserRole} />} />
      <Route element={<Auth />}>
        <Route index element={<Calculation />} />
        <Route path="archive" element={<Archive />} />
        <Route path="archive/item/:id" element={<Archive_item />} />
        <Route path="profile" element={<Manager />} />
        <Route path="logout" element={<Logout updateUserRole={updateUserRole} />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);

const OrgRoutes = ({ updateUserRole }) => (
  <Routes>
    <Route element={<Layout />}>
      <Route path="/login" element={<Login updateUserRole={updateUserRole} />} />
      <Route element={<Auth />}>
        <Route index element={<Calculation />} />
        <Route path="archive" element={<Archive />} />
        <Route path="archive/item/:id" element={<Archive_item />} />
        <Route path="cost" element={<Cost />} />
        <Route path="calculation" element={<Calc />} />
        <Route path="contact" element={<Contact />} />
        <Route path="users" element={<User  />} />
        <Route path="users/item/:id" element={<User_item />} />
        <Route path="users/list/:user_id" element={<Archive />} />
        <Route path="profile" element={<Organization />} />
        <Route path="logout" element={<Logout updateUserRole={updateUserRole} />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);

const DefaultRoutes = ({ updateUserRole }) => (
  <Routes>
    <Route element={<Layout />}>
      <Route path="/login" element={<Login updateUserRole={updateUserRole} />} />
      <Route element={<Auth />}>
        <Route index element={<Home />} />
        <Route path="cost" element={<Cost />} />
        <Route path="calculation" element={<Calc />} />
        <Route path="contact" element={<Contact />} />
        <Route path="archive" element={<Archive />} />
        <Route path="profile/manager" element={<Manager />} />
        <Route path="profile/organization" element={<Organization />} />
        <Route path="organization" element={<Org />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);

export const Router = memo(() => {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user || null;
  });

  const updateUserRole = (newRole) => {
    if (newRole === null) {
      localStorage.clear();
      return navigate("/", { replace: true });
    }
    const user = newRole;
    localStorage.setItem("user", JSON.stringify(user));
    setUserRole(newRole);
  };

  switch (userRole?.role) {
    case "admin":
      return <AdminRoutes updateUserRole={updateUserRole} />;
    case "moderator":
      return <AdminRoutes updateUserRole={updateUserRole} />;
    case "organization":
      return <OrgRoutes updateUserRole={updateUserRole} />;
    case "manager":
      return <ManagerRoutes updateUserRole={updateUserRole} />;
    default:
      return <DefaultRoutes updateUserRole={updateUserRole} />;
  }
});
