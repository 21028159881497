// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.send_btn,.send_btn_repeat
{
    height: 34px;
    padding: 0 20px;
    border: 1px solid #b2bac6;
    border-radius: 8px;
    background: transparent;
    cursor: pointer;
    color: #0c6b60;
    font-size: 10px;
    font-weight: 500;
    stroke-width: 1px;
        float: right;
}
.send_btn
{
    margin-top: 50px;
}
.close_btn
{
    height: 34px;
    padding: 0 20px;
    border: 1px solid #cecece;
    border-radius: 8px;
    background: transparent;
    cursor: pointer;
    color: #cecece;
    font-size: 10px;
    font-weight: 500;
    stroke-width: 1px;
        float: left;
}

.not_found
{
    margin: 10px 0;
    font-size: 18px;
}
.archive__list2 {
  min-width: 1024px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* .archive__item:hover {
  background: #fff;
  border: 1px solid #dde1eb;
} */

.archive__list2 > :nth-child(odd) {
  background: transparent;
  border: 1px solid #dde1eb;
}

.archive__list2 > li {
  width: 100%;
  height: 65px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.w75
{
    width: 75px;
}
.w250
{
    width: 25%;
}`, "",{"version":3,"sources":["webpack://./src/pages/archive/Invoices.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,uBAAuB;IACvB,eAAe;IACf,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;QACb,YAAY;AACpB;AACA;;IAEI,gBAAgB;AACpB;AACA;;IAEI,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,uBAAuB;IACvB,eAAe;IACf,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;QACb,WAAW;AACnB;;AAEA;;IAEI,cAAc;IACd,eAAe;AACnB;AACA;EACE,iBAAiB;EACjB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;;;GAGG;;AAEH;EACE,uBAAuB;EACvB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;;IAEI,WAAW;AACf;AACA;;IAEI,UAAU;AACd","sourcesContent":[".send_btn,.send_btn_repeat\n{\n    height: 34px;\n    padding: 0 20px;\n    border: 1px solid #b2bac6;\n    border-radius: 8px;\n    background: transparent;\n    cursor: pointer;\n    color: #0c6b60;\n    font-size: 10px;\n    font-weight: 500;\n    stroke-width: 1px;\n        float: right;\n}\n.send_btn\n{\n    margin-top: 50px;\n}\n.close_btn\n{\n    height: 34px;\n    padding: 0 20px;\n    border: 1px solid #cecece;\n    border-radius: 8px;\n    background: transparent;\n    cursor: pointer;\n    color: #cecece;\n    font-size: 10px;\n    font-weight: 500;\n    stroke-width: 1px;\n        float: left;\n}\n\n.not_found\n{\n    margin: 10px 0;\n    font-size: 18px;\n}\n.archive__list2 {\n  min-width: 1024px;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n/* .archive__item:hover {\n  background: #fff;\n  border: 1px solid #dde1eb;\n} */\n\n.archive__list2 > :nth-child(odd) {\n  background: transparent;\n  border: 1px solid #dde1eb;\n}\n\n.archive__list2 > li {\n  width: 100%;\n  height: 65px;\n  border-radius: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n\n.w75\n{\n    width: 75px;\n}\n.w250\n{\n    width: 25%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
