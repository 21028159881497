// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination a,
.pagination span {
  display: block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #ddd;
  margin: 0 5px;
  box-sizing: border-box;
  text-decoration: none;
	color: #60656E;
	background: #F0F1F5;
	border-radius: 5px;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
}

.pagination a:hover,
.pagination span:hover {
  background-color: #f5f5f5;
}

.pagination button 
{
	background: unset;
    outline: 0;
    border: none;
    display: flex;
    font-size: 14px;
}

.pagination button.prev
{
	margin-right: 10px;
}

.pagination button.next
{
	margin-left: 10px;
}

.pagination button.prev svg 
{
	margin-right: 5px;
}

.pagination button.next svg 
{
	margin-left: 5px;
	 margin-top: 3px;
}

.pagination .active {
  background-color: #007bff;
  color: #fff;
  border-color: #0C6B60;
}

.pagination .disabled {
  background-color: #ddd;
  color: #999;
  border-color: #ddd;
  cursor: not-allowed;
}

`, "",{"version":3,"sources":["webpack://./src/utils/pagination.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;;EAEE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,qBAAqB;CACtB,cAAc;CACd,mBAAmB;CACnB,kBAAkB;CAClB,kBAAkB;CAClB,gBAAgB;CAChB,eAAe;AAChB;;AAEA;;EAEE,yBAAyB;AAC3B;;AAEA;;CAEC,iBAAiB;IACd,UAAU;IACV,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;;AAEA;;CAEC,kBAAkB;AACnB;;AAEA;;CAEC,iBAAiB;AAClB;;AAEA;;CAEC,iBAAiB;AAClB;;AAEA;;CAEC,gBAAgB;EACf,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".pagination {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 20px;\n}\n\n.pagination a,\n.pagination span {\n  display: block;\n  width: 35px;\n  height: 35px;\n  line-height: 35px;\n  text-align: center;\n  border: 1px solid #ddd;\n  margin: 0 5px;\n  box-sizing: border-box;\n  text-decoration: none;\n\tcolor: #60656E;\n\tbackground: #F0F1F5;\n\tborder-radius: 5px;\n\tfont-style: normal;\n\tfont-weight: 500;\n\tfont-size: 14px;\n}\n\n.pagination a:hover,\n.pagination span:hover {\n  background-color: #f5f5f5;\n}\n\n.pagination button \n{\n\tbackground: unset;\n    outline: 0;\n    border: none;\n    display: flex;\n    font-size: 14px;\n}\n\n.pagination button.prev\n{\n\tmargin-right: 10px;\n}\n\n.pagination button.next\n{\n\tmargin-left: 10px;\n}\n\n.pagination button.prev svg \n{\n\tmargin-right: 5px;\n}\n\n.pagination button.next svg \n{\n\tmargin-left: 5px;\n\t margin-top: 3px;\n}\n\n.pagination .active {\n  background-color: #007bff;\n  color: #fff;\n  border-color: #0C6B60;\n}\n\n.pagination .disabled {\n  background-color: #ddd;\n  color: #999;\n  border-color: #ddd;\n  cursor: not-allowed;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
