import React, { useState } from "react";
import "./login.css";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { NumericFormat, PatternFormat } from "react-number-format";
import { Link, useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../context/service/auth.service";
import { useRegisterMutation } from "../../context/service/auth.service";
import { enqueueSnackbar as EnSn } from "notistack";

export const Login = ({updateUserRole}) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [role, setRole] = useState("manager");
  const [showp, setShowp] = useState({ p1: false, p2: false });

  const [login] = useLoginMutation();
  const [register] = useRegisterMutation();

  // Login submit handler
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    let value, msg, variant;

    value = Object.fromEntries(new FormData(e.target));

    const { data = null, error = null } = await login(value);
    if (error) {
      msg = error.data.message;
      variant = error.data.variant;
      return EnSn(msg, { variant });
    }

    localStorage.setItem("access_token", data?.innerData?.token);
    localStorage.setItem("user", JSON.stringify({ ...data?.innerData?.user }));
    const role = data?.innerData?.user?.role; // Получаем роль из полученных данных
    updateUserRole(data?.innerData?.user); // Обновляем состояние роли пользователя

    msg = data.message;
    variant = data.variant;
    EnSn(msg, { variant });
    return navigate("/", { replace: true }); // Redirect to home or any other route after login
  };

  // Registration submit handler
  const handleRegistrationUser = async (e) => {
    e.preventDefault();
    let value, msg, variant;
    value = Object.fromEntries(new FormData(e.target));
    if (value.phone) {
        value.phone = value.phone.replace(/[\s()-]/g, ''); // Удаляем пробелы, тире и скобки
    }
    const { data = null, error = null } = await register(value);
    if (error) {
      msg = error.data.message;
      variant = error.data.variant;
      return EnSn(msg, { variant });
    }

    msg = data.message;
    variant = data.variant;
    EnSn(msg, { variant });
    return navigate("/");
  };

  const handleRegistrationOrganization = async (e) => {
    e.preventDefault();
    let value, msg, variant;
    value = Object.fromEntries(new FormData(e.target));
    if (value.phone) {
        value.phone = value.phone.replace(/[\s()-]/g, ''); // Удаляем пробелы, тире и скобки
    }
    const { data = null, error = null } = await register(value);
    if (error) {
      msg = error.data.message;
      variant = error.data.variant;
      return EnSn(msg, { variant });
    }

    msg = data.message;
    variant = data.variant;
    EnSn(msg, { variant });
    return navigate("/");
  };

  return (
    <div className="login">
      <div className="login__container">
        {/* Login Form */}
        <form className="login__form__login" onSubmit={handleLoginSubmit}>
          <h1>Вход в личный кабинет</h1>
          <div>
            <input
              type="text"
              name="email"
              placeholder="Логин"
              autoComplete="off"
            />
          </div>

          <div>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Пароль"
            />
            <span onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
            </span>
          </div>

          <button type="submit">Войти</button>
          <Link to="/auth/reset-password">Восстановить пароль</Link>
        </form>

        <div className="login__form__registration">
          <h1>Регистрация</h1>
          <div className="login__form__registration__actions">
            <label
              onClick={() => {
                setRole("manager");
                setShowp({ p1: false, p2: false });
              }}
            >
              <input
                type="radio"
                name="role"
                value="manager"
                hidden
                checked={role === "manager"}
                readOnly
              />
              <b></b>
              <span>Менеджер</span>
            </label>

            <label
              onClick={() => {
                setRole("organization");
                setShowp({ p1: false, p2: false });
              }}
            >
              <input
                type="radio"
                name="role"
                value="organization"
                hidden
                checked={role === "organization"}
                readOnly
              />
              <b></b>
              <span>Организация</span>
            </label>
          </div>

          <form onSubmit={handleRegistrationUser}>
            <div
              className="reg__manager"
              style={{ display: role === "manager" ? "flex" : "none" }}
            >
              <input type="hidden" name="role" value="manager" />
              <input
                type="text"
                name="inn"
                placeholder="ИНН (не обязательно)"
                autoComplete="off"
              />
              <input
                type="email"
                name="email"
                placeholder="Емаил"
                autoComplete="off"
              />
              <div>
                <input
                  type={showp.p1 ? "text" : "password"}
                  name="password"
                  placeholder="Пароль"
                  autoComplete="off"
                />
                <span onClick={() => setShowp({ ...showp, p1: !showp.p1 })}>
                  {showp.p1 ? <IoEyeOutline /> : <IoEyeOffOutline />}
                </span>
              </div>
              <div>
                <input
                  type={showp.p2 ? "text" : "password"}
                  name="password"
                  placeholder="Повторите пароль"
                  autoComplete="off"
                />
                <span onClick={() => setShowp({ ...showp, p2: !showp.p2 })}>
                  {showp.p2 ? <IoEyeOutline /> : <IoEyeOffOutline />}
                </span>
              </div>
              <input
                type="text"
                name="lastname"
                placeholder="Фамилия"
                autoComplete="off"
              />
              <input
                type="text"
                name="fristname"
                placeholder="Имя"
                autoComplete="off"
              />
              <input
                type="text"
                name="patronymic"
                placeholder="Отчество"
                autoComplete="off"
              />
                <PatternFormat
                  format="+7 (###) ###-##-##"
                  allowEmptyFormatting
                  mask="_"
                  name="phone"
                  style={{ letterSpacing: "2px" }}
                />

              <button>Регистрация</button>
              <Link to="/auth/privacy-policy">Политика конфиденциальности</Link>
            </div>
          </form>

          <form onSubmit={handleRegistrationOrganization}>
            <div
              className="reg__organization"
              style={{ display: role === "organization" ? "flex" : "none" }}
            >
              <input type="hidden" name="role" value="organization" />
              <input
                type="text"
                placeholder="Название"
                autoComplete="off"
                name="name"
              />
              <input
                type="text"
                placeholder="Юр Адрес"
                autoComplete="off"
                name="address"
              />
              <div>
                <input
                  type="email"
                  placeholder="Емаил"
                  autoComplete="off"
                  name="email"
                />

                <div>
                  <input
                    type={showp.p1 ? "text" : "password"}
                    name="password"
                    placeholder="Пароль"
                    autoComplete="off"
                  />
                  <span onClick={() => setShowp({ ...showp, p1: !showp.p1 })}>
                    {showp.p1 ? <IoEyeOutline /> : <IoEyeOffOutline />}
                  </span>
                </div>

                <div>
                  <input
                    type={showp.p2 ? "text" : "password"}
                    name="password"
                    placeholder="Повторите пароль"
                    autoComplete="off"
                  />
                  <span onClick={() => setShowp({ ...showp, p2: !showp.p2 })}>
                    {showp.p2 ? <IoEyeOutline /> : <IoEyeOffOutline />}
                  </span>
                </div>

                <input
                  type="text"
                  placeholder="Фамилия"
                  autoComplete="off"
                  name="lastname"
                />
                <input
                  type="text"
                  placeholder="Имя"
                  autoComplete="off"
                  name="fristname"
                />

                <input
                  type="text"
                  placeholder="Отчество"
                  autoComplete="off"
                  name="patronymic"
                />

                <PatternFormat
                  format="+7 (###) ###-##-##"
                  allowEmptyFormatting
                  mask="_"
                  name="phone"
                  style={{ letterSpacing: "2px" }}
                />
              </div>

              <div>
                <input
                  type="text"
                  name="inn"
                  placeholder="ИНН"
                  autoComplete="off"
                />
                <input
                  type="text"
                  name="kpp"
                  placeholder="КПП"
                  autoComplete="off"
                />
                <input
                  type="text"
                  name="ogrn"
                  placeholder="ОГРН"
                  autoComplete="off"
                />
                <input
                  type="text"
                  name="bank"
                  placeholder="Банк"
                  autoComplete="off"
                />
                <input
                  type="text"
                  name="bik"
                  placeholder="БИК"
                  autoComplete="off"
                />
                <input
                  type="text"
                  name="rs"
                  placeholder="Р/С"
                  autoComplete="off"
                />
                <input
                  type="text"
                  name="ks"
                  placeholder="КС"
                  autoComplete="off"
                />
              </div>
              <div className="reg__actions">
                <button>Регистрация</button>
                <Link to="/auth/privacy-policy">
                  Политика конфиденциальности
                </Link>
              </div>
            </div>
          </form>
        </div>

        <div className="login__form__commercial">
          <h1>Комерческая информация</h1>
          <p>
            Для завершения регистрации Вам необходимо подтвердить Email, перейдя
            по ссылке в отправленном Вам письме. Не получили письмо? Для
            завершения регистрации Вам необходимо подтвердить Email, перейдя по
            ссылке в отправленном Вам письме. Не получили письмо? Для завершения
            регистрации Вам необходимо подтвердить Email, перейдя по ссылке в
            отправленном Вам письме. Не получили письмо? Для завершения
            регистрации Вам необходимо подтвердить Email, перейдя по ссылке в
            отправленном Вам письме. Не получили письмо? Для завершения
            регистрации Вам необходимо подтвердить Email, перейдя по ссылке в
            отправленном Вам письме. Не получили письмо?
          </p>
        </div>
      </div>
    </div>
  );
};
