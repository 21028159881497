import React, { useEffect, useState } from "react";
import "./style.css";
import { useParams, useLocation } from 'react-router-dom';
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { NumericFormat, PatternFormat } from "react-number-format";
import { useGetUserByIdQuery } from "../../../context/service/user.service";
import { useUpdateUserMutation, useUpdateUserLogoMutation, useUpdateUserPasswordMutation } from "../../../context/service/user.service";
import { enqueueSnackbar as EnSn } from "notistack";

export const User_item = () => {
  const [show, setShow] = useState({ old: false, new: false, repeat: false });
  const [logo, setLogo] = useState(null);
  const { id } = useParams();
  const { data = null, isLoading } = useGetUserByIdQuery(id);
  const user = data?.innerData;
  const CurrentUser = JSON.parse(localStorage.getItem("user"));

  const handleShow = (type) => {
    setShow((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  // handle form submit
  const [updateUser] = useUpdateUserMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    let value, msg, variant, next;
    value = Object.fromEntries(new FormData(e.target));

    // new password and repeat password must be equal

    msg = "Новый пароль и повторите пароль должны совпадать";
    next = value.new_password !== value.repeat_password;
    if (next) return EnSn(msg, { variant: "warning" });
    delete value.repeat_password;

    value.markup = Number(value.markup.replace(" %", ""));
    // clear empty values
    for (const key in value) if (!value[key]) delete value[key];
    try {
        const { data } = await updateUser({ id: user.id, body: value }).unwrap();
        msg = data?.message || "Успешное обновление пользователя"; // Сообщение по умолчанию
        variant = data?.variant || "success"; // Значение по умолчанию
        EnSn(msg, { variant });
    } catch (error) {
        msg = error?.data?.message || "Ошибка при обновлении пользователя";
        variant = error?.data?.variant || "error"; // Значение по умолчанию
        EnSn(msg, { variant });
    }
  };

  // handle logo update
  const [updateLogo] = useUpdateUserLogoMutation();
  const handleUploadLogo = async (logo) => {
    try {
      let msg, variant;
      const formData = new FormData();
      formData.append("logo", logo);
      const { data, error } = await updateLogo({ id: user.id, body: formData });
      msg = data?.message || error?.data?.message;
      variant = data?.variant || error?.data?.variant || "error";
      if (error) return EnSn(msg, { variant });
      EnSn(msg, { variant });
    } catch (error) {
      EnSn("Слишком большой размер файла", { variant: "error" });
      setTimeout(() => window.location.reload(), 1000);
    }
  };
  const [updateUserPassword] = useUpdateUserPasswordMutation();
const handlePasswordChange = async (e) => {
    e.preventDefault();
    let value, msg, variant;

    // Получаем значения из формы
    value = Object.fromEntries(new FormData(e.target));

    // Проверяем, заполнены ли поля нового пароля и его подтверждения
    if (!value.new_password || !value.repeat_password) {
        return EnSn("Пожалуйста, заполните все поля пароля.", { variant: "warning" });
    }

    // Проверяем, совпадают ли новый пароль и подтверждение
    if (value.new_password !== value.repeat_password) {
        return EnSn("Новый пароль и повторите пароль должны совпадать", { variant: "warning" });
    }
    delete value.repeat_password;
    // Удаляем пустые поля из объекта value
    for (const key in value) if (!value[key]) delete value[key];

    try {
        const { data } = await updateUserPassword({ id: id, body: value }).unwrap();
        msg = data?.message || "Пароль успешно изменен."; // Сообщение по умолчанию
        variant = data?.variant || "success"; // Значение по умолчанию
        EnSn(msg, { variant });
    } catch (error) {
        msg = error?.data?.message || "Ошибка при обновлении пароля";
        variant = error?.data?.variant || "error"; // Значение по умолчанию
        EnSn(msg, { variant });
    }
};
 
  return (
    <div className="profile">
      <form className="profile__form" autoComplete="off" onSubmit={handleSubmit}>
        <div className="profile__form__left">
          <h1>Название организации/ ФИО</h1>
          <div>
            <label>
              <input
                type="email"
                placeholder="Емаил"
                defaultValue={user?.email}
                name="email"
                autoComplete="off"
              />
            </label>
            <label>
              <input
                type="text"
                name="phone"
                placeholder="Номер телефона"
                autoсomplete="disabled"
                defaultValue={user?.phone}
              />
            </label>
          </div>

          <div>
            <label>
              <input
                type="text"
                placeholder="Фамилия"
                defaultValue={user?.lastname}
                name="lastname"
              />
            </label>
            <label>
              <input
                type="text"
                placeholder="Имя"
                defaultValue={user?.fristname}
                name="fristname"
              />
            </label>
            <label>
              <input
                type="text"
                placeholder="Отчество"
                defaultValue={user?.patronymic}
                name="patronymic"
              />
            </label>
          </div>
          {user?.role === 'organization' ? (
            <div className="profile__requisites">
              <h1>Реквизиты</h1>
                <label className="wf100">
                  <input type="text" placeholder="Название:" name="name" defaultValue={user?.name || ''} required />
                </label>
                <label className="wf100">
                  <input type="text" placeholder="Юр Адрес:" name="address" defaultValue={user?.address || ''} required />
                </label>
                <label className="wf50">
                  <input type="text" placeholder="БИК" name="bik" defaultValue={user?.bik || ''} required />
                </label>
                <label className="wf50">
                  <input type="text" placeholder="КПП" name="kpp" defaultValue={user?.kpp || ''} required />
                </label>
                <label className="wf50">
                  <input type="text" placeholder="Р/С" name="rs" defaultValue={user?.rs || ''} required />
                </label>
                <label className="wf50">
                  <input type="text" placeholder="ОГРН" name="ogrn" defaultValue={user?.ogrn || ''} required />
                </label>
                <label className="wf50">
                  <input type="text" placeholder="К/С" name="ks" defaultValue={user?.ks || ''} required />
                </label>
                <label className="wf50">
                  <input type="text" placeholder="Банк" name="bank" defaultValue={user?.bank || ''} required />
                </label>
            </div>
          ) : null}

        </div>

        <div className="profile__form__right">
          <div>
            <p>Процент наценки</p>
            <NumericFormat
              allowLeadingZeros
              thousandSeparator=""
              suffix=" %"
              placeholder="0 %"
              value={user?.markup || 0}
              name="markup"
            />
          </div>

          <figure>
            {logo ? (
              <img
                src={logo ? URL.createObjectURL(logo) : "/icons/pic.svg"}
                alt="logo"
                className={logo ? "selected" : ""}
              />
            ) : (
              <img className="selected" src={user?.logo} alt="" />
            )}
          </figure>

          <label>
            <input
              type="file"
              hidden
              onChange={(e) => {
                setLogo(e.target.files[0]);
                handleUploadLogo(e.target.files[0]);
              }}
              accept="image/*"
            />
            <img src="/icons/download.svg" alt="" />
            <span>Загрузить логотип</span>
          </label>

          <button>Сохранить</button>
        </div>
      </form>

      <form className="password-change-form" autoComplete="off" onSubmit={handlePasswordChange}>
        <h2>Изменить пароль</h2>
        <div className="form_block">
        {(user?.id === CurrentUser?.user_id && CurrentUser?.role !== 'admin' || user?.id === CurrentUser?.user_id && CurrentUser?.role !== 'moderator') && (
          <label>
            <input
              type={show.old ? "text" : "password"}
              placeholder="Старый пароль"
              name="old_password"
              autoComplete="new-password"
            />
            <button onClick={() => handleShow("old")} type="button">
              {show.old ? <IoEyeOutline /> : <IoEyeOffOutline />}
            </button>
          </label>
        )}
          <label>
            <input
              type={show.new ? "text" : "password"}
              placeholder="Новый пароль"
              name="new_password"
            />
            <button onClick={() => handleShow("new")} type="button">
              {show.new ? <IoEyeOutline /> : <IoEyeOffOutline />}
            </button>
          </label>
          <label>
            <input
              type={show.repeat ? "text" : "password"}
              placeholder="Повторите пароль"
              name="repeat_password"
            />
            <button onClick={() => handleShow("repeat")} type="button">
              {show.repeat ? <IoEyeOutline /> : <IoEyeOffOutline />}
            </button>
          </label>
          <button type="submit">Сменить пароль</button>
        </div>
      </form>
    </div>
  );
};

