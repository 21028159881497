import React from 'react';
import './Invoices.css'; // Создайте файл стилей для модального окна
import { useGetInvoicesByIdQuery } from '../../context/service/calculator.service';
import { enqueueSnackbar as EnSn } from "notistack";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { useInWorkCalculatorMutation, useIsPaidInvoceCalculatorMutation } from '../../context/service/calculator.service';

const Invoices = ({ isOpen, onClose, id, onSendNew }) => {
    const [inWorkCalculator] = useInWorkCalculatorMutation();
    const [isPaidInvoceCalculator] = useIsPaidInvoceCalculatorMutation();
    const { data: invoices = [], error, isLoading } = useGetInvoicesByIdQuery(id, { skip: !isOpen });

    if (!isOpen) return null;
    if (isLoading) return <div>Загрузка...</div>;
    if (error) return <div>Ошибка загрузки счетов: {error.message}</div>;

    const handleSendToWork = async (id) => {
        const confirmed = window.confirm("Вы уверены, что хотите отправить в работу этот расчет?");
        if (!confirmed) return;

        let msg, variant;
        const { data = null, error = null } = await inWorkCalculator(id);
        msg = data ? data.message : error?.data?.error;
        variant = data ? data.variant : error?.data?.variant;

        EnSn(msg, { variant });
    };

    function formatDate(date) {
        if (!(date instanceof Date)) {
            date = new Date(date);
        }
        if (isNaN(date.getTime())) {
            return 'Некорректная дата';
        }
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('ru-RU', options).replace(',', 'г.');
    }

    const changePaid = async (id, paid) => {
        const text = (paid === '1') ? 'Вы уверены, что хотите сменить статус на оплачен?' : 'Вы уверены, что хотите сменить статус на не оплачен?';
        const confirmed = window.confirm(text);
        if (!confirmed) return;


        let msg, variant;
        const { data = null, error = null } = await isPaidInvoceCalculator({ id, paid });

        msg = data ? data.message : error?.data?.message;
        variant = data ? data.variant : error?.data?.variant;

        if (msg) {
            EnSn(msg, { variant });
        } else {
            console.error("Ошибка: Сообщение отсутствует");
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <h2>Отправленные счета</h2>
                {invoices?.innerData?.length > 0 ? (
                    <ul className="archive__list2">
                        <li className="archive__header">
                            <p className="w75">id</p>
                            <p className="w250">Калькулятор</p>
                            <p className="w250">Дата счёта</p>
                            <p className="w250">Последняя отправка</p>
                            <p className="w250">Оплачен</p>
                            <p className="w250"></p>
                        </li>
                        {invoices.innerData.map((item) => (
                            <li className="archive__item" key={item.id}>
                                <p className="w75">{item.id}</p>
                                <p className="w250">{id}</p>
                                <p className="w250">{formatDate(item.created_at)}</p>
                                <p className="w250">{formatDate(item.send_at)}</p>
                                <p className="w250">
                                    {item.status === '1' ? <button className="no_button" onClick={() => changePaid(item.id, '0')}><MdCheckBox /></button> : <button className="no_button" onClick={() => changePaid(item.id, '1')}><MdCheckBoxOutlineBlank /></button>}
                                </p>
                                <p className="w250">
                                    <button className="send_btn_repeat" onClick={() => onSendNew(id, item.id)}>Отправить повторно</button>
                                </p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="not_found">Счета не найдены.</p>
                )}
                <button className="send_btn" onClick={() => onSendNew(id, 0)}>Отправить новый счет</button>
            </div>
        </div>
    );
};

export default Invoices;