import { apiSlice } from "./api.service";

export const userApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get all users service: GET - /get/user
    getUsers: builder.query({
      query: ({page, status = 'all', role = 'all'}) =>
        `/get/user?page=${page}&status=${status}&role=${role}`,
      providesTags: ["update_user"],
    }),

    // Get user by id service: GET - /get/user/:id
    getUserById: builder.query({
      query: (id) => `/get/user/${id}`,
      providesTags: ["update_user"],
    }),

    // Update user service: PATCH - /update/user/:id
    updateUser: builder.mutation({
      query: ({ id, body }) => ({
        url: `/update/user/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["update_user"],
    }),

    updateUserPassword: builder.mutation({
      query: ({ id, body }) => ({
        url: `/password/user//${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["update_user"],
    }),

    // Update user logo service: PATCH - /update/logo/:id
    updateUserLogo: builder.mutation({
      query: ({ id, body }) => ({
        url: `/update/logo/${id}`,
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: ["update_user"],
    }),

    // Delete user service: DELETE - /delete/user/:id
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/delete/user/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["update_user"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useUpdateUserMutation,
  useUpdateUserPasswordMutation,
  useUpdateUserLogoMutation,
  useDeleteUserMutation,
} = userApi;
