import React, { useState, useRef, useEffect  } from "react";
import { useParams, useLocation } from 'react-router-dom';
import Select, { components } from 'react-select';
import "./calculation.css";
import { IoIosArrowDown } from "react-icons/io";
import { ModalImage, Lightbox } from 'react-modal-image';
import { PatternFormat, NumericFormat } from "react-number-format";
import { MdKeyboardArrowDown, MdAdd, MdOutlineZoomOutMap } from "react-icons/md";
import { useGetProductQuery } from "../../context/service/product.service";
import { useUpdateCalculatorMutation, useGetCalculatorByIdQuery } from '../../context/service/calculator.service'; 
import { enqueueSnackbar as EnSn } from "notistack";
import Modal from '../calculation/Modal';
import ReactDOM from 'react-dom/client';
import PrintTable from './PrintTable'; // Импортируем компонент таблицы
import './PrintStyles.css'; // Импортируем стили для печати


export const Archive_item = () => {

  const [aboutOrder, setAboutOrder] = useState(false);
  const aboutorderRef = useRef(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [materialPrice, setMaterialPrice] = useState(0);
  const [extraPrice, setExtraPrice] = useState(0)
  const closeAboutOrder = () => setAboutOrder(false);
  const [updateCalculator] = useUpdateCalculatorMutation();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")) || null);
  const [markup, setMarkup] = useState(user?.markup || 0);
  const [markupPrice, setMarkupPrice] = useState(0);
  const [inWork, setInWork] = useState(0);
  const location = useLocation();
  const { printResults } = location.state || {}; // Получаем переданное состояние
  const [hasPrinted, setHasPrinted] = React.useState(false);
  const [isPrint, setIsPrint] = React.useState(false);

  const [fullname, setFullname] = useState('');
  const [date, setDate] = useState(new Date().toLocaleDateString("sv-SE"));
  const [discount, setDiscount] = useState(0);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [items, setItems] = useState({
    details: [
      {
        name: "",
        color: "",
        length: 0,
        thickness: 0,
        width: 0,
        price_metr: 0,
        area  : 0,
        count: 1,
        price : 0
      },
    ],
    borders: [
      {
        model: "",
        height: 0,
        length: 0,
        area  : 0,
        price : 0
      }
    ],
    edge: [
      {
        model: "",
        length: 0,
        price  : 0,
        area  : 0
      }
    ],
    washing: [
      {
        category: "",
        model: "",
        color: "",
        length: 0,
        price: 0,
        count: 1
      }
    ],
    total: {
      details_area: 0,
      details_price: 0,
      borders_area: 0,
      borders_price: 0,
      edge_area: 0,
      edge_price: 0,
      washing_area: 0,
      washing_price: 0
    }
  });
  const { id } = useParams();
  const { data, error, isLoading } = useGetCalculatorByIdQuery(id);
  const [msg, setMsg] = useState(null);
  const [variant, setVariant] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageTitle, setSelectedImageTitle] = useState(null);

    useEffect(() => {
        // Проверяем наличие ошибки
        if (error) {
            setMsg(error.data?.error || 'Произошла ошибка');
            setVariant(error.data?.variant || 'error');
        } 
        // Проверяем наличие данных только если не идет загрузка
        else if (!data && !isLoading) {
            setMsg('Расчеты не найден');
            document.title = "Расчеты не найдены";
            setVariant('error');
        } 
        // Если данные успешно получены
        else if (data) {
            setMsg(data.message || 'Данные успешно получены');
            setVariant(data.status || 'success');

            document.title = "Расчеты №"+data.innerData?.id;
            // Обновляем состояния на основе полученных данных
            setFullname(data.innerData?.fullname || '');
            setEmail(data.innerData?.email || '');
            setPhone(data.innerData?.phone || '');
            setDiscount(data.innerData?.discount || 0);
            setMarkup(data.innerData?.markup - data.innerData?.discount);

            setSelectedOption(JSON.parse(data.innerData?.color));
            setSelectedThickness(JSON.parse(data.innerData?.thickness));
            setExtras(JSON.parse(data.innerData?.extras));
            setExtraPrice(data.innerData?.extras_price);
            handleGradeClick(JSON.parse(data.innerData?.material) || []);
            Calculation_sum(JSON.parse(data.innerData?.items));
            setInWork(data.innerData?.status);

        }
    }, [data, error, isLoading]); // Добавлено isLoading в зависимости

    useEffect(() => {
        // Показываем уведомление, если msg или variant изменились
        if (msg && variant) {
            EnSn(msg, { variant });
        }
    }, [msg, variant]); // Зависимости для useEffect

    useEffect(() => {
      if(items){
        Calculation_sum(items);
      }
    }, [markup, extraPrice]); // Зависимость от markup


    React.useEffect(() => {
        if (printResults && !hasPrinted && !isLoading) {
            // Логика для распечатки результатов
            print();
            setHasPrinted(true); // Устанавливаем состояние, чтобы избежать повторной печати
        }
    }, [printResults, hasPrinted, isLoading]);


    React.useEffect(() => {
        if (isPrint) {
            // Логика для распечатки результатов
            print();
            setIsPrint(false); // Устанавливаем состояние, чтобы избежать повторной печати
        }
    }, [isPrint]);

  const print = () => {
      // Создаем временное окно
      const printWindow = window.open('', '_blank');

      // Проверяем, что новое окно открылось
      if (!printWindow) {
          console.error('Не удалось открыть новое окно для печати.');
          return;
      }
    printWindow.document.write(`
        <html>
            <head>
                <title>Заказ № ${data.innerData?.id}</title>
                <style>
                    /* Здесь можно добавить стили для печати */
                    body {
                        font-family: Arial, sans-serif;
                    }
                </style>
                <link rel="stylesheet" type="text/css" href="/assets/PrintStyles.css" onload="this.onload=null; this.rel='stylesheet'">
            </head>
            <body>
                <div id="print-content"></div> <!-- Контейнер для рендеринга -->
            </body>
        </html>
    `);
    printWindow.document.close(); // Закрываем документ для рендеринга

    printWindow.onload = () => {
        const printContent = printWindow.document.getElementById('print-content');
        const root = ReactDOM.createRoot(printContent); // Создаем корень для рендеринга
        root.render(<PrintTable data={data.innerData} />); // Рендерим компонент в контейнер
        
        // Задержка перед печатью, чтобы компонент успел отрендериться
        setTimeout(() => {
            printWindow.print(); // Открываем диалоговое окно печати
            printWindow.close(); // Закрываем окно после печати
        }, 500); // 500 миллисекунд задержка
    };
  };

  const handleDublicateElement = (element) => {
    const newItemTemplate = {
      details: {
        name: "",
        color: "",
        length: 0,
        thickness: 0,
        width: 0,
        count: 1,
        area: 0,
        price_metr: 0,
        area  : 0,
        price : 0
      },
      borders: {
        model: "",
        height: 0,
        length: 0,
        area  : 0,
        price : 0
      },
      edge: {
        model: "",
        length: 0,
        price  : 0,
        area  : 0
      },
      washing: {
        category: "",
        model: "",
        color: "",
        count: 1,
        price: 0
      },
    };

    setItems((prevState) => ({
      ...prevState,
      [element]: [
        ...prevState[element],
        newItemTemplate[element]
      ]
    }));
  };

  const handleRemoveElement = (element, index) => {
    setItems((prevState) => {
      const newList = prevState[element].filter((_, i) => i !== index);
      return { ...prevState, [element]: newList };
    });
  };

  const [extras, setExtras] = useState({
    install: {},
    options: {}
  });

  const installCount = Array.isArray(extras['install']) ? extras['install'].length : 0;
  const optionsCount = Array.isArray(extras['options']) ? extras['options'].length : 0;

  const material_list = useGetProductQuery('material').data?.innerData || [];
  const colors_list = useGetProductQuery('color').data?.innerData || [];
  const width_list = useGetProductQuery('width').data?.innerData || [];
  const thickness_list = useGetProductQuery('thickness').data?.innerData || [];
  const product_list = useGetProductQuery('product').data?.innerData || [];
  const border_list = useGetProductQuery('border').data?.innerData || [];
  const edge_list = useGetProductQuery('edge').data?.innerData || [];
  const washing_list = useGetProductQuery('sink').data?.innerData || [];
  const install_list = useGetProductQuery('installation').data?.innerData || [];
  const options_list = useGetProductQuery('options').data?.innerData || [];


  const options_colors = colors_list.map((el, i) => {
    const image = el.image !== null ? el.image : '';
    return({value: el.id, label: el.artiarticle_number, name: el.name, image: image, description: el.description, artiarticle_number: el.artiarticle_number, price: el.price, param: el.param}
  )});

  const [optionsColors, setOptionsColors] = useState([]);
  const [optionsWashing, setOptionsWashing] = useState([]);
  const options_width = width_list.map((el, i) => {return({value: el.id, label: el.name, price: el.price})});
  const options_thickness = thickness_list.map((el, i) => {return({value: el.id, label: el.name, price: el.price})});

  const options_product = product_list.map((el, i) => {return({value: el.id, label: el.name, price: el.price})});
  const options_border = border_list.map((el, i) => {return({value: el.id, label: el.name, price: el.price, image: el.image})});
  const options_edge = edge_list.map((el, i) => {return({value: el.id, label: el.name, price: el.price, image: el.image})});
  const options_washing = washing_list.map((el, i) => {return({value: el.id, label: el.name, price: el.price, image: el.image, category: el.description})});

  const options_install = install_list.map((el, i) => {return({value: el.id, label: el.name, price: el.price, description: el.description})});
  const options_options = options_list.map((el, i) => {return({value: el.id, label: el.name, price: el.price, description: el.description})});


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedThickness, setSelectedThickness] = useState(null);
  const [modalData, setModalData] = useState({ items: [], style: '' });

  const handleSelectChange = (option) => {
    setSelectedOption(option);
      const updatedItems = { ...items };
      let updateYes = 'no';
      Object.keys(items).forEach((key) => {
        if (key !== 'total') {
          updatedItems[key].forEach((item, index) => {
            if (option && option.value && updatedItems[key][index] && updatedItems[key][index]['color'] === '') {
              updatedItems[key][index]['color'] = option;
              updateYes = 'yes';
            }
          });
        }
      });
      if(updateYes === 'yes') {
        setItems(updatedItems);
        Calculation_sum(updatedItems);
      }
  };

  const handleThicknessChange = (option) => {
    setSelectedThickness(option);
  };

  useEffect(() => {
        const updatedItems = { ...items };
        let updateYes = 'no';
        Object.keys(items).forEach((key) => {
          if (key != 'total') {
            updatedItems[key].forEach((item, index) => {
              if (selectedThickness && selectedThickness.value && updatedItems[key][index] && updatedItems[key][index]['thickness'] == '') {
                updatedItems[key][index]['thickness'] = selectedThickness;
                updateYes = 'yes';
              }
            });
          }
        });
        if(updateYes === 'yes') {
          Calculation_sum(updatedItems);
        }
    }, [selectedThickness]); // Зависимость от markup

  const openModal = (data = [], style = '', type, name, index, value) => {

      const item = { items: data, style: style, name: name, type: type,index: index, value: value};
      setModalData(item);
      setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalData([]); // Очистка данных модального окна
  };

  const CustomDropdownIndicator = (props) => {
    const handleClick = (event) => {
      event.stopPropagation(); // Остановить всплытие события
      openModal(props.options, props.style, props.type, props.name, props.index, props.selectProps.value); // Передать массив в модальное окно
    };


    return (
      <components.DropdownIndicator {...props}>
        <div onClick={handleClick} style={{ cursor: 'pointer', padding: '8px' }}>
          <IoIosArrowDown />
        </div>
      </components.DropdownIndicator>
    );
  };

  const validateItemsAndExtras = (items, extras) => {
    let isValid = true;
    let errorMessages = [];

    // Проверка `items`

    if (!Array.isArray(items) || items.length === 0) {
        //isValid = false;
        //errorMessages.push("Элементы не могут быть пустыми.");
    } else {
        items.forEach((item, index) => {
            if (!item.someField) {  // Замените `someField` на реальные ключи в ваших объектах
                isValid = false;
                errorMessages.push(`Элемент с индексом ${index} недействителен.`);
            }
        });
    }


    return { isValid, errorMessages };
  };

  const handleUpdateCalc = async (e, work = 0) => {
      e.preventDefault();
      let msg, variant;

      const form = e.target;

      const material = selectedGrade;
      const color = selectedOption;
      const thickness = selectedThickness;
      const markup = user?.markup;
      const material_price = materialPrice;
      const extras_price = extraPrice;
      const markup_price = markupPrice;

      // Выполняем валидацию
      const { isValid, errorMessages } = validateItemsAndExtras(items, extras);

      if (!isValid) {
          msg = errorMessages.join(" ");  // Соединяем сообщения об ошибках
          variant = "error";
          EnSn(msg, { variant }); // Показываем уведомление о ошибке
          return;
      }

        const payload = {
            date,
            fullname,
            phone,
            email,
            discount,
            material,
            color,
            thickness,
            items,
            extras,
            markup,
            material_price,
            extras_price,
            markup_price,
            ...(work === 1 && { status: 1 })

        };

      if (!id) {
          EnSn("ID расчет не найден.", { variant: "error" });
          return;
      }


      // Отправка запроса через API
      
      const { data = null, error = null } = await updateCalculator({ id, ...payload });

      msg = data ? data.message : error?.data?.message;
      variant = data ? data.variant : error?.data?.variant;
      if (error) return EnSn(msg, { variant });
      EnSn(msg, { variant });

  };


  const handleSendToWork = (event) => {
    handleUpdateCalc(event, 1);
  };

  const handleSave = (event) => {
    handleUpdateCalc(event);
  };

  const handlePrint = (event) => {
    setIsPrint(true);
  };

  const Calculation_sum = async (items) => {
    const updatedItems = { ...items };
    const markupFactor = markup ? 1 + markup / 100 : 1; // Получаем коэффициент на основе процента
    let totalMarkup = 0;

    Object.keys(items).forEach((key) => {
        if (key === 'details') {
            updatedItems[key].forEach((item, index) => {
                // Удаляем пробелы и преобразуем в число
                const length = parseFloat(String(item.length).replace(/\s+/g, '')) || 0; // Заменяем все пробелы
                const width = parseFloat(String(item.width).replace(/\s+/g, '')) || 0; // Заменяем все пробелы

                updatedItems[key][index]['area'] = (length * width / 1000000).toFixed(2) * parseFloat(String(item['count']).replace(/\s+/g, '')) || 0;
            });
        }

        if (key !== 'total') {
            updatedItems[key].forEach((item, index) => {
                // Инициализация значений в total только при первой итерации
                if (index === 0) {
                    updatedItems['total'][`${key}_price`] = 0;
                    updatedItems['total'][`${key}_area`] = 0;
                }

                // Расчет цен и площадей в зависимости от типа ключа
                switch (key) {
                    case 'details':
                        const detailPriceMetr = item['name'].price + item['thickness'].price + item['color'].price;
                        const markupFactorMetr = markup ? 1 + (markup - discount) / 100 : 1
                        updatedItems[key][index]['price_metr'] = (detailPriceMetr * markupFactorMetr).toFixed(2);
                        updatedItems[key][index]['price'] = (item['area'] * detailPriceMetr * markupFactorMetr).toFixed(2);

                        const detailPrice = item['area'] * detailPriceMetr || 0;
                        totalMarkup += detailPrice * (markupFactor - 1); // Увеличиваем сумму наценки

                        updatedItems['total'][`${key}_price`] += (detailPrice).toFixed(2) * (markupFactor).toFixed(2) || 0;
                        updatedItems['total'][`${key}_area`] += parseFloat(updatedItems[key][index]['area']) || 0;
                        break;

                    case 'edge':
                        const edgeLength = parseFloat(String(item['length']).replace(/\s+/g, '')) || 0;
                        updatedItems[key][index]['price'] = (edgeLength / 1000 * item['model'].price * markupFactor).toFixed(2);

                        const edgePrice = (edgeLength / 1000 * item['model'].price) || 0;
                        totalMarkup += edgePrice * (markupFactor - 1); // Увеличиваем сумму наценки

                        updatedItems['total'][`${key}_area`] += edgeLength / 1000 || 0;
                        updatedItems['total'][`${key}_price`] += (edgePrice).toFixed(2) * (markupFactor).toFixed(2) || 0;
                        break;

                    case 'borders':
                        const borderLength = parseFloat(String(item['length']).replace(/\s+/g, '')) || 0;
                        const thickness_price = item['thickness'] ? item['thickness'].price : 0;
                        updatedItems[key][index]['price'] = ((borderLength / 1000) * (item['model'].price + thickness_price) * markupFactor).toFixed(2);

                        const borderPrice = ((borderLength / 1000) * (item['model'].price + thickness_price)) || 0;
                        totalMarkup += borderPrice * (markupFactor - 1); // Увеличиваем сумму наценки

                        updatedItems['total'][`${key}_area`] += borderLength / 1000 || 0;
                        updatedItems['total'][`${key}_price`] += (borderPrice).toFixed(2) * (markupFactor).toFixed(2) || 0;
                        break;

                    case 'washing':
                     // Получаем цену и количество без пробелов
                        const price = item['model'].price;
                        const count = item['count'] || 0;

                        // Вычисляем цену
                        updatedItems[key][index]['price'] = price * parseFloat(String(count).replace(/\s+/g, '')) * markupFactor;

                        const washingPrice = price * parseFloat(String(count).replace(/\s+/g, '')) || 0;

                        // Обновляем маржу
                        totalMarkup += washingPrice * (markupFactor - 1);

                        updatedItems['total'][`${key}_price`] += washingPrice * markupFactor || 0;

                        break;
                }
            });
        }
    });

    setMarkupPrice(totalMarkup); // Устанавливаем итоговую наценку
  
    const totalMaterialPrice = Object.keys(updatedItems.total)
        .filter(key => key.endsWith('_price')) 
        .reduce((sum, key) => {
            return sum + (parseFloat(updatedItems.total[key]) || 0); 
        }, 0);
    setMaterialPrice(totalMaterialPrice);
    setTotalPrice(totalMaterialPrice + extraPrice);
    setItems(updatedItems);
  };

  const handleModalSave = (type, style,quantities,index,value) => {
    if (style === 'delivery') {
        setExtras((prevExtras) => {
            const updatedExtras = { ...prevExtras }; // Копируем предыдущие значения
            
            const filteredQuantities = quantities.filter(
                (item) => item && item.count !== undefined && item.count > 0
            );

            if (type === 'install') {
                updatedExtras.install = filteredQuantities; // Сохраняем данные для установки
            } else if (type === 'options') {
                updatedExtras.options = filteredQuantities; // Сохраняем данные для опций
            }
            
            // Расчеты для totalPrice на основе обновленных extras
            const totalInstallPrice = Array.isArray(updatedExtras.install) ? updatedExtras.install.reduce((sum, item) => {
                const price = item.price || 0; // Предполагаем, цена может отсутствовать
                const count = item.count || 0; // Количество
                return sum + price * count; // Суммируем цену умноженную на количество
            }, 0) : 0;

            const totalOptionsPrice = Array.isArray(updatedExtras.options) ? updatedExtras.options.reduce((sum, item) => {
                const price = item.price || 0;
                const count = item.count || 0;
                return sum + price * count;
            }, 0) : 0;

            const totalExtraPrice = totalInstallPrice + totalOptionsPrice;
            setExtraPrice(totalExtraPrice); // Устанавливаем полученную сумму в extraPrice
            
            const newTotalPrice = materialPrice + totalExtraPrice;
            setTotalPrice(newTotalPrice);
            return updatedExtras; // Возвращаем обновлённый объект

        });
    } else if (style === 'color') {
        if (quantities && value) {
            const updatedItems = { ...items };
            updatedItems[type][index][quantities] = value;
            setItems(updatedItems);
        } else if (!quantities && type === 'color' && style === 'color') {

      const updatedItems = { ...items };
            let updateYes = 'no';
            Object.keys(items).forEach((key) => {
              if (key !== 'total') {
                updatedItems[key].forEach((item, index) => {
                  if (value && value.value && updatedItems[key][index] && updatedItems[key][index]['color'] === '') {
                    updatedItems[key][index]['color'] = value;
                    updateYes = 'yes';
                  }
                });
              }
            });
            if(updateYes === 'yes') {
              setItems(updatedItems);
            }

            setSelectedOption(value);
        }
    }

    Calculation_sum(items);
  };

  const handleInputChangeItems = async (event, type, index, name = '') => {

    const target = event.target;
    const value = target ? target.type ==='checkbox' ? target.checked : target.value : event;
    const price = event && event.price ? event.price : '';
    const name_t = target ? target.name : name;

    if(name_t === 'category') {

      const filter_washing = options_washing.filter(washing => 
        washing.category && washing.category.trim().toLowerCase() === value.value.trim().toLowerCase()
      );
      setOptionsWashing(filter_washing);
    }
    const updatedItems = items[type].map((item, itemIndex) => {
        if (itemIndex === index) { 
            const updatedItem = {
                ...item, 
                [name_t ? name_t : name]:  value
            };
            return updatedItem;
        }
        return item; // возвращаем элемент без изменений
    });
    const NewUpdatedItems = {
      ...items,
      [type]: [
        ...updatedItems
      ]
    };
    setItems((prevState) => (NewUpdatedItems));

    Calculation_sum(NewUpdatedItems,type);

  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    let msg, variant;
    switch(name) {
      case 'date':
        setDate(value);
        break;
      case 'fullname':
        setFullname(value);
        break;
      case 'phone':
        setPhone(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'discount':
        const discountPercentage = value.replace(' %', '');
        if (discountPercentage > data.innerData?.markup) {
          const markupValue = data.innerData?.markup || 0; // Если markup равен NaN, то будет возвращено 0
          msg = 'Максимум ' + markupValue + '%';
          setMarkup(markupValue);
          variant = 'error';
          EnSn(msg, { variant });
          return;
        } else {
         setDiscount(discountPercentage);
          const newMarkup = user?.markup - discountPercentage; // Вычитаем скидку из маркета
          setMarkup(newMarkup); // Устанавливаем новое значение маркет
        }
        break;
    }
  }

  const [selectedGrade, setSelectedGrade] = useState(null);
  const handleGradeClick = (grade) => {

    const filter_color = options_colors.filter(color => {
      if (color.param) {
        try {
          return JSON.parse(color.param).includes(grade.id);
        } catch (error) {
          console.error("Ошибка при парсинге color.param:", error);
          return false; // В случае ошибки, возвращаем false
        }
      }
      return false;
    });
    setSelectedGrade(grade);
    setOptionsColors(filter_color);


    const updatedItems = { ...items };
    let updateYes = 'no';
    Object.keys(items).forEach((key) => {
      if (key !== 'total') {
        updatedItems[key].forEach((item, index) => {
          if (item['color'] && item['color'].value && !filter_color.some(color => color.value === item['color'].value)) {
            updatedItems[key][index]['color'] = '';
            updateYes = 'yes';
          }
        });
      }
    });
    if(updateYes === 'yes') {
      setItems(updatedItems);
      Calculation_sum(updatedItems);
    }

    if (selectedOption && selectedOption.value && !filter_color.some(color => color.value === selectedOption.value)) {
        setSelectedOption(null);
    }
  }
  const handleGradeUnselect = () => setSelectedGrade(null);
    if (isLoading) {
        return <div>Загрузка...</div>; // Или любой другой индикатор загрузки
    }
    if(variant === 'error') {
      return <div>Ничего не найдено</div>;
    }

  const handleLightboxOpen = (material) => {
    setSelectedImage(material.image);
    setSelectedImageTitle(material.name);
    setLightboxOpen(true);
  };

  const handleLightboxClose = () => {
    setLightboxOpen(false);
    setSelectedImage(null);
    setSelectedImageTitle(null);
  };

  return (
    <div className="calculation">
    <form onSubmit={handleSave}
    >
      <div className="calculation__header">
        <div className={"about__order_mobile" + (aboutOrder ? " active" : "")}>
          <label onClick={() => setAboutOrder(true)}>
            <span>Данные о заказе:</span>
            <MdKeyboardArrowDown />
          </label>

          <div
            ref={aboutorderRef}
            className={
              "about__order_mobile__content" + (aboutOrder ? " active" : "")
            }
          >
            <p onClick={closeAboutOrder}>
              <span>Данные о заказе:</span>
              <MdKeyboardArrowDown />
            </p>

            <input
              type="date"
              name="date"
              defaultValue={date}
              onChange={handleChange}

            />
            <input
              type="text"
              placeholder="ФИО клиента"
              name="fullname"
              autoComplete="off"
              defaultValue={fullname}
              onChange={handleChange}

            />

            <PatternFormat
              format="+7 (###) ###-##-##"
              allowEmptyFormatting
              mask="_"
              name="phone"
              value={phone}
              placeholder="Телефон клиента"
              onChange={handleChange}

            />

            <input
              type="email"
              placeholder="Email клиента"
              name="email"
              defaultValue={email}
              autoComplete="off"
              onChange={handleChange}

            />

            <NumericFormat
              allowLeadingZeros
              thousandSeparator=""
              suffix=" %"
              placeholder="Скидка для клиента"
              value={discount || 0}
              name="discount"
              onChange={handleChange}
            />
            <div className="order__price">
              <p>Итоговая цена:</p>
              <NumericFormat
                value={totalPrice}
                allowLeadingZeros
                thousandSeparator=" "
                
                suffix=" ₽"
                displayType="text"

              />
            </div>

            <div className="order__buttons">
              {inWork === 0 ? (
                <button type="button" onClick={handleSendToWork}>
                  Отправить в работу
                </button>
              ) : null}
              <button >Изменить</button>
            </div>
            <button type="button" onClick={handlePrint}>
              <img src="/icons/print.svg" alt="" />
              <span>Распечатать</span>
            </button>
          </div>
        </div>

        <div className="calculation__header_left">
          <div className="calculation__box">
            <h2 className="calculation__title">Марка материала:</h2>

            <ol className="calculation__grades">
              {material_list.map((material) => {
                const isSelected = selectedGrade?.id === material?.id;
                return (
                  <li
                    key={material.id}
                    className={
                      "calculation__grade" + (isSelected ? " active" : "")
                    }
                    onClick={() =>
                      isSelected
                        ? handleGradeUnselect()
                        : handleGradeClick(material)
                    }
                  >

                    <img src={material.image} alt={material.name} />
                    <img
                      src="/icons/checkbox.svg"
                      alt="checkbox"
                      className={"checkbox" + (isSelected ? " active" : "")}
                    />
                  <div className="lightbox-icon"
                  onClick={(e) => {
                    e.stopPropagation(); // Останавливаем распространение события
                    handleLightboxOpen(material);
                  }}>
                    <MdOutlineZoomOutMap />
                  </div>
                  </li>
                );
              })}
            </ol>
          </div>

          <div className="calculation__box">
            <h2 className="calculation__title">Цвет:</h2>
            <Select className="calculation__select_new" name="color" classNamePrefix="react-select" options={optionsColors}
                components={{
                    DropdownIndicator: (props) => (
                        <CustomDropdownIndicator {...{ ...props, type: 'color', style: 'color' }} />
                    ),
                }}
            value={selectedOption}
            onChange={handleSelectChange}
            formatOptionLabel={color => (
              <div className="color_option">
                <div className="span">{color.name}<span>{color.label}</span></div>
                <img src={color.image} />
              </div>
            )}/>

          </div>          
          <div className="calculation__box">
            <h2 className="calculation__title">Толщина изделия:</h2>
            <Select className="calculation__select_new" name="thickness" value={selectedThickness} onChange={handleThicknessChange} classNamePrefix="react-select" options={options_width} formatOptionLabel={width => (
              <div className="color_option">
                <div className="span">{width.label}</div>
              </div>
            )}/>
          </div>
        </div>
        <div className="about__order">
          <h2>Данные о заказе:</h2>
          <input
            type="date"
            name="date"
            defaultValue={date}
            onChange={handleChange}
          />
          <input
            type="text"
            placeholder="ФИО клиента"
            name="fullname"
            autoComplete="off"
            defaultValue={fullname}
            onChange={handleChange}
          />

          <PatternFormat
            format="+7 (###) ###-##-##"
            allowEmptyFormatting
            mask="_"
            name="phone"
            placeholder="Телефон клиента"
            value={phone}
            onChange={handleChange}
          />

          <input
            type="email"
            placeholder="Email клиента"
            name="email"
            autoComplete="off"
            defaultValue={email}
            onChange={handleChange}
          />
            <NumericFormat
              allowLeadingZeros
              thousandSeparator=""
              suffix=" %"
              placeholder="Скидка для клиента"
              value={discount || 0}
              name="discount"
              onChange={handleChange}
            />

          <div className="order__price">
            <p>Итоговая цена:</p>
            <NumericFormat
              value={totalPrice}
              allowLeadingZeros
              thousandSeparator=" "
              
              suffix=" ₽"
              displayType="text"
            />
          </div>

          <div className="order__buttons">
              {inWork === 0 ? (
                <button type="button" onClick={handleSendToWork}>
                  Отправить в работу
                </button>
              ) : null}
            <button>Изменить</button>
          </div>
          <button type="button" onClick={handlePrint}>
            <img src="/icons/print.svg" alt="" />
            <span>Распечатать</span>
          </button>
        </div>
      </div>

      <div className="calculation__detail">
        <h2 className="calculation__title">Детали изделия:</h2>

        {items?.details?.map((item, index) => {

          return (
            <div className="calculation__item" key={index}>
              <div className="calculation__item_top">
                <div className="w270">
                  <span>Название</span>
                  <Select className="calculation__select_new calculation__select_full"
                  name="name"
                  value={item.name}
                  onChange={(e) => {handleInputChangeItems(e, 'details', index, 'name')}}
                  classNamePrefix="react-select" options={options_product}
                  formatOptionLabel={color => (
                    <div className="color_option">
                      <div className="span">{color.label}</div>
                    </div>
                  )}/>
                </div>

                <div className="w145">
                  <span>Толщина (мм)</span>
                  <Select className="calculation__select_new calculation__select_full" name="thickness" onChange={(e) => {handleInputChangeItems(e, 'details', index, 'thickness')}}
                  value={item.thickness}
                  classNamePrefix="react-select" options={options_width} formatOptionLabel={color => (
                    <div className="color_option">
                      <div className="span">{color.label}</div>
                    </div>
                  )}/>
                </div>

                <div className="w350">
                  <span>Цвет</span>
                  <Select className="calculation__select_new" name="color" onChange={(e) => {handleInputChangeItems(e, 'details', index, 'color')}}
                  classNamePrefix="react-select"
                  components={{
                      DropdownIndicator: (props) => (
                          <CustomDropdownIndicator {...{ ...props, style: 'color', type: 'details', name: 'color', index: index }} />
                      ),
                  }}
                  options={optionsColors}
                  value={item.color}
                  formatOptionLabel={color => (
                    <div className="color_option">
                      <div className="span">{color.name}<span>{color.label}</span></div>
                      <img src={color.image} />
                    </div>
                  )}/>
                </div>

                <label className="w135">
                  <span>Длинна (мм)</span>
                  <NumericFormat
                    thousandSeparator=" "
                    onChange={(e) => {handleInputChangeItems(e, 'details', index)}}
                    allowLeadingZeros
                    value={item.length}
                    name="length"
                    placeholder="0"
                  />
                </label>

                <label className="w135">
                  <span>Ширина (мм)</span>
                  <NumericFormat
                    thousandSeparator=" "
                    onChange={(e) => {handleInputChangeItems(e, 'details', index)}}
                    allowLeadingZeros
                    value={item.width}
                    name="width"
                    placeholder="0"
                  />
                </label>

                <label className="w100">
                  <span>Количество</span>
                  <NumericFormat
                    thousandSeparator=" "
                    value={item.count}
                    onChange={(e) => {handleInputChangeItems(e, 'details', index)}}
                    allowLeadingZeros
                    name="count"
                    placeholder="0"
                  />
                </label>
              </div>

              <div className="calculation__item_bottom">
                <p>
                  <i>Цена за метр:</i>
                  <NumericFormat
                    value={item.price_metr}
                    allowLeadingZeros
                    thousandSeparator=" "
                    name="price_metr"
                    
                    suffix=" ₽"
                    displayType="text"
                  />
                </p>
                <p>
                  <i>Площадь:</i>
                  <NumericFormat
                    value={item.area}
                    allowLeadingZeros
                    thousandSeparator=" "
                    
                    name="area"
                    suffix=" м²"
                    displayType="text"
                  />
                </p>
                <p>
                  <i>Итоговая цена:</i>
                  <NumericFormat
                    value={item.price}
                    allowLeadingZeros
                    thousandSeparator=" "
                    
                    name="total_price"
                    suffix=" ₽"
                    displayType="text"
                  />
                </p>
                <p className="red_noty">Возможность изготовления заказа менее 1.5м<sup>2</sup> уточняйте отдельно</p>
              </div>
              {index !== 0 ?
                <button
                  className="calculation__item_remove"
                  onClick={(e) => {
                      e.preventDefault(); // Предотвращает отправку формы
                      handleRemoveElement('details', index);
                  }}
                >
                <span>Убрать элемент</span>
                <MdAdd />
              </button>
                :'' }
                <button
                  className="calculation__item_add"
                    onClick={(e) => {
                        e.preventDefault(); // Предотвращает отправку формы
                        handleDublicateElement('details');
                    }}
                >
                  <span>Добавить еще элемент</span>
                  <MdAdd />
                </button>
            </div>
          );
        })}

        <div className="calculation__item_info">
          <p>
            <i>Общая площадь всех элементов</i>
            <NumericFormat
              value={items.total.details_area}
              allowLeadingZeros
              thousandSeparator=" "
              
              suffix=" м²"
              displayType="text"
            />
          </p>

          <p>
            <i>Итоговая цена всех элементов</i>
            <NumericFormat
              value={items.total.details_price}
              allowLeadingZeros
              thousandSeparator=" "
              
              suffix=" ₽"
              displayType="text"
            />
          </p>
        </div>
      </div>

      <div className="calculation__detail">
        <h2 className="calculation__title v2">Выбрать бортик:</h2>
        {items?.borders?.map((item, index) => {
          return (
            <div className="calculation__item" key={index}>
              <div className="calculation__item_top">
                <div className="w270">
                  <span>Выбрать модель</span>
                  <Select className="calculation__select_new calculation__select_full" name="model"
                  onChange={(e) => {handleInputChangeItems(e, 'borders', index, 'model')}}
                  classNamePrefix="react-select"
                  components={{
                      DropdownIndicator: (props) => (
                          <CustomDropdownIndicator {...{ ...props, style: 'color', type: 'borders', name: 'model', index:index }} />
                      ),
                  }}
                  options={options_border}
                  value={item.model}
                  formatOptionLabel={border => (
                    <div className="color_option">
                      <div className="span">{border.label}</div>
                    </div>
                  )}/>
                </div>

                <label className="w135">
                  <span>Высота (мм)</span>
                  <Select className="calculation__select_new calculation__select_full"
                  name="thickness" onChange={(e) => {handleInputChangeItems(e, 'borders', index, 'thickness')}}
                  classNamePrefix="react-select"
                  options={options_thickness}
                  value={item.thickness}
                  formatOptionLabel={border => (
                    <div className="color_option">
                      <div className="span">{border.label}</div>
                    </div>
                  )}/>

                </label>

                <label className="w135">
                  <span>Длина (мм)</span>
                  <NumericFormat
                    thousandSeparator=" "
                    allowLeadingZeros
                    name="length"
                    value={item.length}
                    onChange={(e) => {handleInputChangeItems(e, 'borders', index)}}
                    placeholder="0"
                  />
                </label>
              </div>

              <div className="calculation__item_bottom">
                <p>
                  <i>Общая длинна:</i>
                  <NumericFormat
                    value={parseFloat(String(item.length).replace(' ', '')) / 1000}
                    allowLeadingZeros
                    thousandSeparator=" "
                    
                    suffix=" пог. м"
                    displayType="text"
                  />
                </p>
                <p>
                  <i>Итоговая цена:</i>
                  <NumericFormat
                    value={item.price}
                    allowLeadingZeros
                    thousandSeparator=" "
                    
                    suffix=" ₽"
                    displayType="text"
                  />
                </p>
              </div>

              {index !== 0 ?
                <button
                  className="calculation__item_remove"
                  onClick={(e) => {
                      e.preventDefault(); // Предотвращает отправку формы
                      handleRemoveElement('borders', index);
                  }}
                >
                <span>Убрать элемент</span>
                <MdAdd />
              </button>
                :'' }
                <button
                  className="calculation__item_add"
                  onClick={(e) => {
                      e.preventDefault(); // Предотвращает отправку формы
                      handleDublicateElement('borders');
                  }}
                >
                  <span>Добавить еще элемент</span>
                  <MdAdd />
                </button>
            </div>
          );
        })}


        <div className="calculation__item_info">
          <p>
            <i>Общая длина</i>
            <NumericFormat
              value={items.total.borders_area }
              allowLeadingZeros
              thousandSeparator=" "
              
              suffix=" пог. м"
              displayType="text"
            />
          </p>

          <p>
            <i>Итоговая цена всех элементов</i>
            <NumericFormat
              value={items.total.borders_price}
              allowLeadingZeros
              thousandSeparator=" "
              
              suffix=" ₽"
              displayType="text"
            />
          </p>
        </div>
      </div>

      <div className="calculation__detail">
        <h2 className="calculation__title v2">Выбрать кромку:</h2>

        {items?.edge?.map((item, index) => {
          return (
            <div className="calculation__item"  key={index}>
              <div className="calculation__item_top">
                <div className="w270">
                  <span>Выбрать модель</span>
                  <Select className="calculation__select_new calculation__select_full"
                  name="model"
                  onChange={(e) => {handleInputChangeItems(e, 'edge', index, 'model')}}
                  classNamePrefix="react-select"
                  components={{
                      DropdownIndicator: (props) => (
                          <CustomDropdownIndicator {...{ ...props, style: 'color', type: 'edge', name: 'model', index: index}} />
                      ),
                  }}
                  options={options_edge}
                  value={item.model}
                  formatOptionLabel={edge => (
                    <div className="color_option">
                      <div className="span">{edge.label}</div>
                    </div>
                  )}/>
                </div>

                <label className="w135">
                  <span>Длинна (мм)</span>
                  <NumericFormat
                    name="length"
                    value={item.length}
                    onChange={(e) => {handleInputChangeItems(e, 'edge', index)}}
                    thousandSeparator=" "
                    allowLeadingZeros
                    placeholder="0"
                  />
                </label>

              </div>

              <div className="calculation__item_bottom">
                <p>
                  <i>Общая длинна: </i>
                  <NumericFormat
                    value={parseFloat(String(item.length).replace(' ', '')) / 1000}
                    allowLeadingZeros
                    thousandSeparator=" "
                    
                    suffix=" пог. м"
                    displayType="text"
                  />
                </p>
                <p>
                  <i>Итоговая цена:</i>
                  <NumericFormat
                    value={item.price}
                    allowLeadingZeros
                    thousandSeparator=" "
                    
                    suffix=" ₽"
                    displayType="text"
                  />
                </p>
              </div>

              {index !== 0 ?
                <button
                  className="calculation__item_remove"
                  onClick={(e) => {
                      e.preventDefault(); // Предотвращает отправку формы
                      handleRemoveElement('edge', index);
                  }}
                >
                <span>Убрать элемент</span>
                <MdAdd />
              </button>
                :'' }
                <button
                  className="calculation__item_add"
                  onClick={(e) => {
                      e.preventDefault(); // Предотвращает отправку формы
                      handleDublicateElement('edge');
                  }}
                >
                  <span>Добавить еще элемент</span>
                  <MdAdd />
                </button>
            </div>
          );
        })}

        <div className="calculation__item_info">
          <p>
            <i>Общая длина</i>
            <NumericFormat
              value={items.total.edge_area}
              allowLeadingZeros
              thousandSeparator=" "
              
              suffix=" пог. м"
              displayType="text"
            />
          </p>

          <p>
            <i>Итоговая цена всех элементов</i>
            <NumericFormat
              value={items.total.edge_price}
              allowLeadingZeros
              thousandSeparator=" "
              
              suffix=" ₽"
              displayType="text"
            />
          </p>
        </div>
      </div>

      <div className="calculation__detail">
        <h2 className="calculation__title v2">Выбрать мойку или раковину:</h2>
        {items?.washing?.map((item, index) => {
          return (
            <div className="calculation__item"  key={index}>
              <div className="calculation__item_top">
                <div className="w270">
                  <span>Категория</span>
                  <Select className="calculation__select_new calculation__select_full" onChange={(e) => {handleInputChangeItems(e, 'washing', index, 'category')}} name="category" classNamePrefix="react-select"
                  options={[{value: 'sink', label: 'Раковина'}, {value: 'washing', label: 'Мойка'}]}
                  value={item.category}
                  formatOptionLabel={washing => (
                    <div className="color_option">
                      <div className="span">{washing.label}</div>
                    </div>
                  )}/>
                </div>

                <div className="w270">
                  <span>Выбрать модель</span>
                  <Select className="calculation__select_new calculation__select_full"
                  onChange={(e) => {handleInputChangeItems(e, 'washing', index, 'model')}}
                  components={{
                      DropdownIndicator: (props) => (
                          <CustomDropdownIndicator {...{ ...props, style: 'color', type: 'washing', name: 'model', index: index }} />
                      ),
                  }}
                  name="model"
                  value={item.model}
                  classNamePrefix="react-select"
                  options={optionsWashing} formatOptionLabel={washing => (
                    <div className="color_option">
                      <div className="span">{washing.label}</div>
                    </div>
                  )}/>
                </div>

                <div className="w350">
                  <span>Цвет</span>
                  <Select className="calculation__select_new"
                  name="color"
                  onChange={(e) => {handleInputChangeItems(e, 'washing', index, 'color')}}
                  classNamePrefix="react-select"
                  components={{
                      DropdownIndicator: (props) => (
                          <CustomDropdownIndicator {...{ ...props, style: 'color', type: 'washing', name: 'color', index: index }} />
                      ),
                  }}
                  value={item.color}
                  options={optionsColors}
                  formatOptionLabel={color => (
                    <div className="color_option">
                      <div className="span">{color.name}<span>{color.label}</span></div>
                      <img src={color.image} />
                    </div>
                  )}/>
                </div>

                <label className="w100">
                  <span>Количество</span>
                  <NumericFormat
                    thousandSeparator=" "
                    allowLeadingZeros
                    value={item.count}
                    onChange={(e) => {handleInputChangeItems(e, 'washing', index)}}
                    name="count"
                    placeholder="0"
                  />
                </label>
              </div>

              <div className="calculation__item_bottom">
                <p>
                  <i>Общое количество:</i>
                  <NumericFormat
                    value={item.count}
                    allowLeadingZeros
                    thousandSeparator=" "
                    
                    suffix=""
                    displayType="text"
                  />
                </p>
                <p>
                  <i>Итоговая цена:</i>
                  <NumericFormat
                    value={item.price}
                    allowLeadingZeros
                    thousandSeparator=" "
                    
                    suffix=" ₽"
                    displayType="text"
                  />
                </p>
              </div>

              {index !== 0 ?
                <button
                  className="calculation__item_remove"
                  onClick={(e) => {
                      e.preventDefault(); // Предотвращает отправку формы
                      handleRemoveElement('washing', index);
                  }}
                >
                <span>Убрать элемент</span>
                <MdAdd />
              </button>
                :'' }
                <button
                  className="calculation__item_add"
                  onClick={(e) => {
                      e.preventDefault(); // Предотвращает отправку формы
                      handleDublicateElement('washing');
                  }}

                >
                  <span>Добавить еще элемент</span>
                  <MdAdd />
                </button>
            </div>
          );
        })}
      </div>

      <div className="calculation__detail">
        <h2 className="calculation__title v2">Дополнительные опции:</h2>
        <div className="calculation__additional">
          <button type="button" onClick={() => openModal(options_options, 'delivery', 'options')}>{installCount > 0 ? `Выбрано (${optionsCount}) услуги` : 'Добавить дополнительные опции'}</button>

          <p>
            <img src="/icons/warning.svg" alt="" />
            <span>
              В этом блоке Вы можете добавить дополнительные опции для изделия:
              вырезы для моек, варочных панелей, дополнительные элементы и
              прочее.
            </span>
          </p>
        </div>
      </div>

      <div className="calculation__detail">
        <h2 className="calculation__title v2">Установка и доставка:</h2>

        <div className="calculation__additional">
          <button type="button" onClick={() => openModal(options_install, 'delivery', 'install')}>{optionsCount > 0 ? `Выбрано (${installCount}) услуги` : 'Добавить дополнительные опции'}</button>
          <p>
            <img src="/icons/warning.svg" alt="" />
            <span>
              В этом блоке Вы можете добавить опции, связанные с установкой и
              доставкой изделия.
            </span>
          </p>
        </div>
      </div>

      <div className="calculation__total">
        <p>
          <i>Итоговая цена:</i>
          <NumericFormat
            value={totalPrice}
            allowLeadingZeros
            thousandSeparator=" "
            
            suffix=" ₽"
            displayType="text"
          />
        </p>

        <div>
              {inWork === 0 ? (
                <button type="button" onClick={handleSendToWork}>
                  Отправить в работу
                </button>
              ) : null}

          <button>
            <span>Изменить</span>
          </button>
        </div>

        <button type="button" onClick={handlePrint}>
          <img src="/icons/print.svg" alt="" />
          <span>Распечатать</span>
        </button>
      </div>

      {isModalOpen && <Modal isOpen={isModalOpen} onClose={closeModal} items={modalData.items} name={modalData.name} value={modalData.value} index={modalData.index} extras={extras} type={modalData.type} style={modalData.style} onSave={handleModalSave} />}

      {lightboxOpen && (
        <Lightbox
          small={selectedImage} // Миниатюра изображения
          large={selectedImage} // Большое изображение для модального окна
          alt={selectedImageTitle} // Альтернативный текст
          onClose={handleLightboxClose} // Закрытие модального окна
        />
      )}
      </form>
    </div>



  );
};
